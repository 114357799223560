.radiobutton {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  user-select: none;
}

.radiobuttonCircle {
  display: flex;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--borderColor);
  background: white !important;
  border-radius: 8px;
  box-sizing: border-box;
}

.marginRight {
  margin-right: var(--smallMargin);
}

.innerCircle {
  opacity: 0;
  width: 8px;
  height: 8px;
  background: #666;
  border-radius: 4px;
}

.visibleCircle {
  opacity: 1;
}

.disabled {
  opacity: var(--disabledOpacity);
}

.constrainSize {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  flex-grow: 0;
}
