.processMap svg text[id^='label:'],
.processMap svg text[id^='value:'] {
  cursor: pointer;
  user-select: none;
}

.processMap svg {
  max-width: 100%;
  /* so text close to the border will not get clipped */
  overflow: visible;
}

.processMap {
  width: 100%;
  height: 100%;
  margin: var(--standardMargin) 0;
  padding: 0 var(--standardMargin);
  box-sizing: border-box;
}

.labelText,
.valueText,
.labelTextClickable {
  font-family: var(--appFont) !important;
  user-select: none;
  fill: var(--surface1Color);
}

:global(.dark) {
  .labelText,
  .valueText,
  .labelTextClickable,
  .processMap svg text {
    fill: var(--whiteColor);
  }
}

.labelTextClickable,
.labelText {
  font-weight: 600;
}

.labelTextClickable {
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
}

.labelTextClickable:hover {
  fill: var(--accent4Color);
}

.valueText {
  font-size: 12px;
  font-weight: 400;
}

.writableSignalText {
  text-decoration: underline;
  user-select: none;
  cursor: pointer;
}

.writableSignalText:hover {
  fill: var(--accent3Color);
}

.header {
  background-color: white;
  z-index: 2;
  height: var(--toolbarHeight);
  width: calc(100%);
  display: flex;
  clear: both;
  align-items: center;
  justify-content: center;
  padding-left: var(--standardMargin);
  padding-right: var(--standardMargin);
  box-sizing: border-box;
  margin-top: -10px;
  border-bottom: 1px solid var(--dividerColor);
}

.clickableRootElement {
  cursor: pointer;
  pointer-events: all;
}

.clickableRootElement:hover {
  fill: var(--accent4Color);
}

.plainBox {
  margin-bottom: var(--largeMargin);
  width: 100%;
  display: flex;
  justify-content: center;
}

.unselectedSignal {
  color: var(--textColor);
}

.selectedSignal {
  color: var(--accent3Color);
  font-weight: 600;
}

.error {
  margin-bottom: var(--hugeMargin);
}

.panelContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.panelEditorBody {
  padding: 0 !important;
}

.panelEditorModal {
  max-width: 95vw !important;
  max-height: 95vh !important;
}
