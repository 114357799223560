.contextMenu {
  position: absolute;
}

.linkHeader {
  border-bottom: 1px solid var(--tableBorderColor);
  padding: var(--smallMargin);
  font-size: 13px;
}

.linkHeaderRow {
  width: 100%;
  display: flex;
  align-items: center;
  user-select: none;
  gap: var(--insetMargin);
}

.linkHeaderValue {
  min-width: 90px;
  display: flex;
  justify-content: flex-end;
}
