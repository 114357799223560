:root {
  --centeredInset: clamp(var(--largeMargin), 2vw, 48px);
  --sidebarWidth: clamp(195px, 15vw, 320px);
}

:root {
}

:root {
}

.sidebar {
  box-sizing: border-box;
  background: white;
  user-select: none;
  font-size: 0.8rem;
  transform: translateZ(0);
  box-shadow: var(--navBarShadow);
  z-index: var(--sideBarZIndex);
  border: 0;
  padding-bottom: 10px;
  flex-direction: column;
  display: flex;
  pointer-events: auto;
  transition: opacity var(--sidebarSpeed) ease-out;
  width: 66px;
  flex-grow: 1;
  flex-shrink: 0;
  overflow-y: auto;
  height: 100%;
}

.sidebar.expanded {
  width: var(--sidebarWidth);
  overflow-y: auto;
}

.globalLink {
  width: 100%;
  font-size: clamp(1.1em, 1vw, 1.2em);
  font-weight: 400;
}

.globalLink:hover {
  background-color: var(--surface5Color);
  text-decoration: none;
}

.globalLink {
  color: var(--textColor);
  display: block;
  width: 100% !important;
  padding: var(--standardMargin) var(--standardMargin) var(--standardMargin)
    20px;
  box-sizing: border-box;
  text-decoration: none;
}

.globalLink.expanded {
  padding: var(--standardMargin) var(--standardMargin) var(--standardMargin)
    var(--centeredInset);
}

.globalLinkActive,
.globalAccountLinkActive {
  background-color: var(--accent1Color);
  color: var(--whiteColor);

  svg {
    fill: var(--whiteColor);
    stroke: var(--whiteColor);
  }
  &:hover {
    background-color: var(--accent1Color);
    color: var(--whiteColor);
  }
}

.globalAccountLink {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

.globalAccountLinkActive,
.globalAccountLinkActive:hover {
  background: var(--accent1Color);
}

.locationButton {
  width: 44px;
  border-radius: 22px;
  display: flex;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 30px;
  transition: background-color var(--sidebarSpeed) ease-out;
  user-select: none;
  z-index: var(--sideBarZIndex);
  color: #cacaca;
}

.locationButton.expanded {
  transition: background-color var(--sidebarSpeed) ease-out;
  margin-right: clamp(0px, 0.5vw, var(--standardMargin));
}

.locationInfo {
  display: flex;
  background: white;
  padding-top: var(--largeMargin);
  justify-content: center;
  align-items: center;
  padding-bottom: var(--standardMargin);
  cursor: pointer;
  user-select: none;
}

.locationInfo:hover {
  background: var(--surface5Color);
}

.locationInfo.expanded {
  justify-content: flex-end;
}

.logo {
  padding-left: var(--centeredInset);
  font-size: clamp(23px, 1vw, 26px);
  font-weight: 700;
  padding-top: 4px;
  color: var(--surface1Color);
  flex-grow: 1;
}

.loadingSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--hugeMargin);
}

.expandingSpace {
  flex-grow: 1;
}

.sideContent {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow-x: visible;
  z-index: 501;
}

.locationArrow {
  width: 100%;
  align-items: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.locationArrow svg {
  color: var(--surface3Color);
  stroke: var(--surface3Color);
  fill: var(--surface3Color);
  width: 34px;
  height: 34px;
  transform: rotate(0deg);
  transition: transform var(--sidebarSpeed) ease-out;
}

.locationArrow.expanded svg {
  transform: rotate(180deg);
  transition:
    transform var(--sidebarSpeed) ease-out,
    background-color var(--sidebarSpeed) ease-out;
}

.navTitle {
  width: 100%;
  color: var(--textColor);
  text-transform: uppercase;
  padding-top: var(--largeMargin);
  padding-left: var(--centeredInset);
  padding-bottom: var(--smallMargin);
  font-weight: 700;
  box-sizing: border-box;
  min-height: 44px;
}

.bottomSection {
  margin-bottom: var(--standardMargin);
}

.accountScreen {
  position: relative;
  height: 1px;
  width: 1px;

  overflow: visible;
  left: 64px;
  background: white;
}

.accountScreen.expanded {
  left: var(--sidebarWidth);
}

.settingsLink {
  display: flex;
  cursor: pointer;
}

.linkContainer {
  display: flex;
}

.icon.expanded {
  margin-right: var(--standardMargin);
  width: 20px;
}

.tenantSelector {
  margin-top: var(--smallMargin);
  text-transform: none;
  padding-left: var(--centeredInset);
  display: flex;
}

.tenantSelectorComponent {
  flex-grow: 1;
}

.tenantSelector > div:only-child {
  margin-right: var(--largeMargin);
}

.tenantSelector button {
  flex-shrink: 0;
  margin-left: var(--standardMargin);
  margin-right: var(--standardMargin);
}

.navLinkSection a.active {
  border: 1px solid yellow;
}
