/*
 * Use a separate container for padding within a scroller.
 * See: https://bugzilla.mozilla.org/show_bug.cgi?id=748518
 */
.modalBody {
  position: relative;
  flex: 1 1 auto;
  overflow-y: auto;
  padding: var(--largeMargin);
  height: 100%;
  background: var(--surface6Color);
  z-index: 2;
  min-height: 40px;
}

.loading > * {
  opacity: 0.25;
}

.modalLoadingOverlay {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100%);
  height: calc(100%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 1;
  text-shadow:
    -1px -1px 0 rgba(255, 255, 255, 0.7),
    1px -1px 0 rgba(255, 255, 255, 0.7),
    -1px 1px 0 rgba(255, 255, 255, 0.7),
    1px 1px 0 rgba(255, 255, 255, 0.7);
  font-weight: 600;
  pointer-events: none;
  color: var(--blackColor);
}

.modalLoadingOverlay * {
  opacity: 1;
}

.modalLoadingOverlay > * + * {
  margin-top: var(--standardMargin);
}

.spinner {
  box-shadow: 0 0px 15px 6px rgba(0, 0, 0, 0.15);
  max-height: 50%;
  max-width: 50%;
}

.withoutPadding {
  padding: 0;
}

.withWhiteColor {
  background: var(--whiteColor);
}

.children {
  height: 100%;
  width: 100%;
}

.loading .children {
  filter: blur(2px);
}

.disableInput {
  pointer-events: none;
}
