.tableContainer {
  flex-grow: 1;
  overflow-y: scroll;
  padding: 0px var(--standardMargin) var(--standardMargin) var(--standardMargin);
  box-sizing: border-box;
}

.staticTable {
  width: 100%;
  margin-bottom: var(--largeMargin);
  user-select: none;
}
