.modalContainer {
  font-size: 0.85rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--modalZIndex);
}

.modalBackdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: var(--largeMargin);
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity var(--modalSpeed);
  will-change: opacity;
  background: var(--backdropColor);
  z-index: 0;
}

:root {
  --outerPadding: var(--hugeMargin);
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: var(--maxModalWidth);
  max-height: var(--maxModalHeight);
  width: 100%;
  height: 100%;
  opacity: 1;
  user-select: text;
  padding: var(--outerPadding);
}

@media (max-height: 900px) {
  .modal {
    max-height: 100% !important;
  }
}

.modalBackground {
  position: absolute;
  left: var(--outerPadding);
  top: var(--outerPadding);
  width: calc(100% - var(--outerPadding) * 2 - 1px);
  height: calc(100% - var(--outerPadding) * 2 - 1px);
  border-radius: 8px;
  box-shadow: var(--backdropColor);
}

.disabled {
  opacity: var(--disabledOpacity);
}

.closeButton {
  position: absolute;
  top: var(--outerPadding);
  right: var(--outerPadding);
  width: var(--modalHeaderHeight);
  height: var(--modalHeaderHeight);
  text-align: center;
  font-size: 1.1em;
  line-height: var(--modalHeaderHeight);
  cursor: pointer;
  z-index: 2;
}

.closeIcon {
  display: block;
  margin: 0 auto;
  height: var(--modalHeaderHeight);
  stroke: var(--iconColor);

  &:hover {
    stroke: var(--surface3Color);
  }
}

.buttonSpace {
  margin-right: 10px;
}

.closeButton .disabled {
  pointer-events: none;
}

@media (max-height: var(--modalBreakpointHeight)) {
  .closeIcon {
    height: var(--modalContractedHeaderHeight);
  }
}

/*
 * Transition
 */

.enter {
  opacity: 0;
  will-change: opacity;

  .modal {
  }
}

.enterActive {
  opacity: 1;

  .modal {
    pointer-events: none;
  }
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  pointer-events: none;
}
