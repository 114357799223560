.alarmIndicator {
  font-weight: bold;
}

.alarmIndicator,
.tooltip {
  display: inline-block;
}

.larmNONE {
  color: green;
  fill: green;
  stroke: green;
}

.larmC {
  color: var(--severity2Color);
  fill: var(--severity2Color);
  stroke: var(--severity2Color);
}

.larmB {
  color: var(--severity1Color);
  fill: var(--severity1Color);
  stroke: var(--severity1Color);
}

.larmA {
  color: var(--severity0Color);
  fill: var(--severity0Color);
  stroke: var(--severity0Color);
}

.tooltip {
  cursor: help;
}
