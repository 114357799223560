:root {
  --filterBoxWidth: 350px;
}

.tagContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--insetMargin));
}

.tagContainer div {
  margin-left: 0 !important;
  margin-right: var(--insetMargin);
  margin-top: var(--insetMargin);
}

.linkContainer {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.9em;
  padding-top: var(--insetMargin);
}

.nameContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.nameContainer > div:first-child {
  margin-right: var(--smallMargin);
}

.typeSelect {
  min-width: 150px;
}

.tagSelect {
  min-width: 220px;
}

.summary {
  display: flex;
  margin-bottom: var(--largeMargin);
}

.summaryText {
  font-size: 0.8em;
  margin-left: var(--smallMargin);
}

.summaryToolbarItem {
  margin-top: var(--smallMargin);
}

.summaryItem + .summaryItem {
  margin-left: var(--largeMargin);
}

.summaryItem label {
  font-size: var(--labelFontSize);
  color: var(--labelColor);
  font-weight: var(--labelWeight);
  margin-left: 0;
}

.dateSubtitle {
  margin-top: var(--insetMargin);
  font-size: 0.9em;
  color: var(--surface3Color);
}

.filterContainer {
  width: var(--filterBoxWidth);
  position: relative;
  left: 0px;
  top: 40px;
  border: 1px solid var(--borderColor);
  border-radius: 6px;
  box-shadow: var(--navBarShadow);
  background: white;
  padding: var(--standardMargin);
  box-sizing: border-box;
}

.filterItem {
  display: flex;
  flex-direction: column;
  min-height: 53px;
  justify-content: flex-end;
}

.overflowingItem {
  overflow-y: visible;
  overflow-x: visible;
  height: 1px;
  width: 1px;
  z-index: 2;
  position: relative;
}

.filterContainer label {
  font-size: var(--labelFontSize);
  margin-left: 0;
}

.dropDownButton svg {
  margin-right: 0 !important;
  margin-left: var(--smallMargin);
}

.unlinkButton {
  margin-top: var(--standardMargin);
}

.limitLine {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  line-height: initial;
  text-overflow: ellipsis;
}

.limitLine + .limitLine {
  margin-top: var(--insetMargin);
}

.filterToolbar {
  margin-top: calc(-1 * var(--largeMargin));
}
