:root {
  --segmentControlSpeed: 0.2s;
}

.segmentControl {
  line-height: normal;
  cursor: pointer;
  display: inline-flex;
  width: auto;
  align-items: stretch;
  user-select: none;
  outline: 0;
}

.vertical {
  flex-direction: column;
  align-items: flex-start;
}

.segmentItem > * {
  pointer-events: none;
}

.segmentItem {
  background: white;
  min-height: 34px;
  box-sizing: border-box;
  border: 1px solid var(--borderColor);
  border-right: 1px solid transparent;
  padding: 7px 12px 7px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  min-width: 100px;
  opacity: 1;
  color: var(--textColor);
}

.segmentItem svg {
  margin-top: 0px;
  margin-right: 12px;
  font-size: var(--baseSvgFontSize) !important;
  color: var(--iconColor);
}

.segmentItem:first-child {
  border-left: 1px solid var(--borderColor);
  border-radius: 5px 0px 0px 5px;
}

.segmentItem:last-child {
  border-right: 1px solid var(--borderColor);
}

.activeSegmentItem + .segmentItem {
  border-left: 1px solid var(--accent1Color);
}

.compactSegmentItem {
  min-width: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.segmentItem:last-child {
  border-radius: 0px 5px 5px 0px;
}

.segmentItem:only-child {
  border-radius: 5px;
}

.vertical .segmentItem {
  border: 1px solid var(--borderColor);
  border-bottom: 0;
  border-radius: 0;
  width: 100%;
  justify-content: flex-start;
  padding-left: 20px;
  padding-right: 20px;
}

.vertical .segmentItem:first-child {
  border-radius: 5px 5px 0 0;
}

.vertical .segmentItem:last-child {
  border-radius: 0 0 5px 5px;
  border-bottom: 1px solid var(--borderColor);
}

.vertical .segmentItem:only-child {
  border-radius: 5px;
}

.vertical .activeSegmentItem,
.vertical .activeSegmentItem:last-child,
.vertical .activeSegmentItem:first-child,
.activeSegmentItem,
.activeSegmentItem:last-child,
.activeSegmentItem:first-child {
  border-color: var(--accent1Color);
}

.vertical .activeSegmentItem + .segmentItem {
  border-top-color: var(--accent1Color);
}

.activeSegmentItem {
  background: var(--accent1Color);
  color: white;
  border-color: var(--accent1Color);
  opacity: 1;
}

.activeSegmentItem svg {
  color: white;
  opacity: 1;
}

.expanding {
  width: 100%;
}

.expandingSegmentItem {
  flex: 1;
  min-width: auto;
}

.disabledSegmentItem {
  pointer-events: none;
  opacity: 0.35;
}
