.keyValues {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--standardMargin);
}

.value {
  font-size: var(--tableFontSize);
}

.tagSelect {
  width: 100%;
}

.splitSection {
  flex-grow: 1;
  width: 1px;
  margin-right: var(--largeMargin);
}

.adjustedSection {
  margin-top: -2px;
}
.adjustedSection > * + * {
  margin-top: var(--standardMargin);
}
.network {
  display: flex;
  flex-direction: row;
}

.hardwareBody {
  display: flex;
}

.hardwareModal {
  width: 600px;

  height: auto;
}

.error {
  border: 1px solid var(--errorColor);
}

.deviceInfo {
  margin-bottom: var(--standardMargin);
}

.useMinimumHeight {
  min-height: 570px;
}

.buttonHeader {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--largeMargin);
}

.expandingButtons {
  display: flex;
}

.expandingButtons button {
  flex-grow: 1;
}

.saveButton {
  margin-top: var(--standardMargin);
  width: 100%;
}

.line {
  width: 100%;
  display: flex;
  align-items: center;
}

.logHeader {
  margin-top: var(--standardMargin);
  display: flex;
  align-items: center;
}

.logHeader div {
  margin-left: var(--smallMargin);
}

.logLines {
  display: flex;
  flex-direction: column;
  font:
    var(--subtitleFontSize) 'SFMono-Regular',
    Consolas,
    'Liberation Mono',
    Menlo,
    'Courier New',
    monospace;
}

.signalStrength {
  margin-top: var(--smallMargin);
  font-size: var(--tableFontSize);
}
