:root {
  --userAccessPadding: 5px;
}

.segmentItem {
  min-height: auto !important;

  padding-top: 4px !important;
  padding-bottom: 4px !important;
  font-size: 0.9em !important;
}

.accessRightsItem {
  min-height: 30px !important;
  font-size: 0.9em !important;
}

.dialog {
  max-width: 600px !important;
  height: auto;
}

.accessDialog {
  max-width: 550px !important;
  height: auto;
  max-height: 750px !important;
}

.table {
  width: 100%;
  font-size: 0.9em;
}

.table tr {
  border-bottom: 1px solid var(--surface4Color);
}

.table th {
  padding-bottom: 5px;
  color: #999;
}

.table td {
  padding: 5px 0 5px 0;
  vertical-align: center;
}

.table th {
  text-align: left;
}

.headerLine svg {
  color: var(--accent3Color) !important;
}
