.dateTimeWrapper {
  display: flex;
  height: 100%;
  align-items: center;
  width: 100%;
  border: 1px solid transparent;

  [class*='TextInput-focus'],
  [class*='DateInput-dateInput'],
  input,
  input:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

.dateTimeWrapper > div {
  width: 100%;
  box-sizing: border-box;
}

.wrap {
  background: var(--inputBackground);
  border: 1px solid var(--borderColor);
  border-radius: 3px;
}

.expandingWidth {
  width: 100%;
}

.dateInput {
  height: 100%;
}
