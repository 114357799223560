.inputTable {
  display: flex;
  flex-direction: column;
}

.inputTable > * {
  margin-left: 0px !important;
  margin-bottom: var(--standardMargin);
  width: 100%;
}
