.detailsItem {
  overflow-y: hidden;
  box-sizing: border-box;
}

.detailsItem:first-child {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
}

.detailsItem:last-child {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-grow: 1;
}

.treeList {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  height: 1px;
}

.box {
  background: var(--whiteColor);
  border-right: 1px solid var(--surface4Color);
  box-sizing: border-box;
  overflow: auto;
  padding: 0;
}

.box:last-child {
  border-right: 0;
  margin-right: 0;
}

.treeBox {
  padding-left: 0;
  padding-right: 0;
}

.filterBox {
  box-sizing: border-box;
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: var(--smallMargin);
  padding-right: 10px;
  height: 30px;
  width: 100%;
}

.treeView {
  overflow-y: hidden !important;
}
