.bullet {
  font-size: 30px;
  display: inline-block;
  line-height: 19px;
}

.container {
  padding: var(--largeMargin) var(--standardMargin);
  overflow-y: auto;
}

.searchField {
  margin-bottom: var(--standardMargin);
}
