.gridLayout {
  height: 100% !important;
}

.panelFullWidth {
  display: block !important;
  transition-property: none !important;
  position: unset !important;
  width: 100% !important;
  transform: translate(0px, 0px) !important;
  margin-bottom: var(--standardMargin);
}

:global .react-resizable-handle {
  position: absolute;
  width: 24px;
  height: 24px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

:global .react-resizable-handle::after {
  content: '';
  position: absolute;
  right: 6px;
  bottom: 3px;
  width: 7px;
  height: 7px;
  border-right: 2px solid var(--borderColor);
  border-bottom: 2px solid var(--borderColor);
}

:global .react-resizable-hide > .react-resizable-handle {
  display: none;
}
