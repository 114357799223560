.settingBody {
  font-size: 0.8rem;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: var(--hugeMargin);
}

.noPaddingBody {
  padding: 0;
}

.settingDialog {
  width: 640px;
  max-height: 604px;
  height: auto !important;
}

.weatherDialog {
  width: 570px;
  max-height: 760px;
}

.mapDialog {
  width: 700px;
  max-height: 760px;
}

.deviceDialog {
  width: var(--treeDialogWidth) !important;
  max-height: var(--treeDialogHeight) !important;
}

.deviceSettingBody {
  background: #fff;
  font-size: 0.9rem;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
}

.treeView {
  padding-top: 10px;
  font-size: 1em;
  background: transparent;
}

.largeMap {
  width: 100%;
  height: 100%;
  z-index: 0;
  box-sizing: border-box;
  border: 1px solid var(--borderColor);
}

.map {
  position: relative;
  width: 100%;
  height: 156px;
  z-index: 0;
  box-sizing: border-box;
  border: 1px solid var(--surface3Color);
  cursor: default;
}

.mapOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}

.settingTable {
  width: 100%;
  margin-top: 20px;
}

.settingTable th {
  font-weight: 600;
  text-align: left;
  height: 25px;
}

.settingTable td:first-child {
  padding-right: 30px;
}

.settingTable td {
  padding-bottom: 15px;
}

.disabled {
  opacity: 0.7;
}

.addAnotherContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.addAnother {
  font-size: var(--labelFontSize);
  color: var(--labelColor);
  font-weight: var(--labelWeight);
  user-select: none;
  margin-left: var(--smallMargin);
}

.inputSection {
  width: 1px;
  flex-grow: 3;
  margin-right: var(--hugeMargin);
  display: flex;
  flex-direction: column;
  gap: var(--standardMargin);
}

.mapSection {
  width: 1px;
  flex-grow: 2;
}

.upperSection {
  width: 100%;
  display: flex;
  margin-bottom: var(--standardMargin);
}

.toastHeader {
  font-size: 15px;
  margin-bottom: var(--standardMargin);
  padding-bottom: var(--standardMargin);
  border-bottom: 1px solid var(--borderColor);
}

.nodeTypeSelector {
  margin-bottom: var(--standardMargin);
}

.editMeteorologyPointForNode {
  max-width: 900px;
}
