:root {
  --size: 13px;
}

.statusCircle {
  width: var(--size);
  height: var(--size);
  border-radius: var(--size);
  margin-top: 1px;
  flex-shrink: 0;
}

.statusOk {
  background: green;
}

.statusError {
  background: var(--errorColor);
}

.statusWarning {
  background: #e68e00;
}

.statusIdle {
  background: var(--accent1Color);
}
