:root {
  --styledMarkerHoverColor: rgb(31, 168, 183);
}

.container {
  min-width: 150px;
  display: flex;
  color: white;
  font-size: var(--labelFontSize);
  font-weight: normal;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
}

.innerWrapper:not(.isCurrentNode):hover {
  & > .container,
  & ~ .arrowContainer > .arrow {
    background: var(--styledMarkerHoverColor);
  }
}

.wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0px;
  display: none;
  z-index: 2;
}

/* Fixes onload issue with FadeInAnimation */
.isVisible {
  display: block;
}

.innerWrapper {
  position: absolute;
  display: flex;
  bottom: 14px;
  left: 50%;
  transform: translateX(-50%);
  flex-direction: column;
  border-radius: var(--tableBorderRadius);
  box-shadow: 0 3px 14px rgb(0 0 0 / 60%);
  overflow: hidden;
  background-color: var(--accent1Color);

  &.isCurrentNode {
    background-color: var(--accent3Color);
    cursor: default;

    & ~ .arrowContainer > .arrow {
      background-color: var(--accent3Color);
    }
  }
}

.signalValue {
  padding: var(--standardMargin) 5px var(--standardMargin) var(--standardMargin);
  background-color: var(--accent3Color);
  color: white;
  font-size: var(--tableFontSize);
}

.information {
  padding: var(--standardMargin);
}

.name {
  margin-bottom: 2px;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
}

.arrowContainer {
  position: absolute;
  content: '';
  width: 40px;
  height: 20px;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  overflow: hidden;
}

/* CSSTransition animations */
.arrow {
  width: 17px;
  height: 17px;
  transform: rotate(45deg);
  background-color: var(--accent1Color);
  margin: -10px auto 0;
}
