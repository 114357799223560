.colorBar {
  height: 32px;
  width: 100%;
  border-radius: var(--insetMargin);
  cursor: pointer;
  display: flex;
  box-sizing: border-box;
}

.flex {
  flex-grow: 1;
}

.infoText {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 9px;
  color: var(--placeholderColor);
  font-size: var(--labelFontSize);
}

.removeIcon {
  width: 15px;
  height: 15px;
  stroke: var(--errorColor);
  background: var(--semiTransparentWhite);
  margin-right: 0px !important;
  border-radius: var(--insetMargin);
}

.noColor {
  border: solid 1px var(--borderColor);
  background: white;
}
