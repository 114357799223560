.alarmSeverityContainer {
  width: 100%;
  display: flex;
}

.alignCenter {
  padding-left: 0;
  align-items: center;
  justify-content: center;
}

.alarmSeverityCircle {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 13px;
  border: 1px solid rgba(200, 200, 200, 0.5);
}

.alarmSeverityCircle > * {
  margin-top: -1px;
}
