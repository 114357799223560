.error {
  color: var(--errorColor);
  font-size: 0.9em;
}

.container {
  width: 100%;
  box-sizing: border-box;
}

.content {
  width: calc(100%);
  background-color: var(--surface6Color);
  height: 550px;
  box-sizing: border-box;
  border: 1px solid var(--surface4Color);
  overflow-y: scroll;
  border-radius: 4px;
}

.fullscreenContent {
  height: 100%;
}

.monoText {
  white-space: pre;
  font:
    var(--subtitleFontSize) 'SFMono-Regular',
    Consolas,
    'Liberation Mono',
    Menlo,
    'Courier New',
    monospace;
  padding: 15px;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--standardMargin);
}

.select {
  width: 200px;
}
