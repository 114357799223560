.progressBar {
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.progressBarFiller {
  background: var(--accent1Color);
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
}
