:root {
  --borderColor: solid #e8e8e8;
  --titleColor: var(--accent1Color);
}

.outerContent {
  width: 100%;
  height: 100%;
  display: flex;
}

.main {
  height: 100%;
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: hidden;
  margin: 0 !important;
}

.locationContainer {
  width: 100%;
  flex-grow: 1;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
