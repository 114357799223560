.searchContainer {
  background: var(--surface5Color);
  padding: var(--standardMargin);
  border: 1px solid var(--borderColor);
  box-sizing: border-box;
  flex-direction: row;
  display: flex;
}

.searchContainer > * + * {
  margin-left: var(--standardMargin);
}

.searchField {
  width: 100%;
  box-sizing: border-box;
}
