.remove {
  fill: none;
  stroke: inherit;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 3px;
}

.removeNoPadding {
  fill: none;
  stroke: inherit;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 4px;
}
