.containerWithMessage {
  width: 100%;
  height: 100%;
  background-color: var(--surface6Color);
}

.closeButton {
  align: center;
  margin-right: var(--smallMargin);
  margin-top: var(--standardMargin);
}
.mainContainer {
  overflow: auto;
  border-top: var(--tableBorderStyle);
}

.message {
  padding-top: var(--standardMargin);
  padding-left: var(--standardMargin);
}
