:global .Select__option {
  font-size: var(--labelFontSize) !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 10px !important;
  color: var(--blackColor) !important;

  svg {
    color: var(--iconColor);
    margin-right: var(--smallMargin);
  }
}

:global .Select__input > input:focus {
  outline: 0 !important;
}

:global .Select__multi-value {
  border-radius: 3px !important;
  overflow: hidden;
  max-height: 23px;
}

:global .Select__multi-value__remove {
  background: var(--surface4Color);
}

:global .Select__multi-value__label {
  display: flex;
  align-items: center;
}

:global .Select__multi-value__remove svg {
  fill: var(--iconColor);
}

:global .Select__multi-value__label {
  padding: 3px 5px !important;
  background: var(--accent1Color);
  color: var(--whiteColor) !important;
  font-size: 12px !important;
}

:global .Select__menu-notice--no-options {
  font-size: 14px;
}

:global .Select__single-value {
  line-height: initial !important;
}

:global .Select__value-container {
  padding-left: var(--smallMargin) !important;
  padding-right: var(--smallMargin) !important;
}

:global .Select__control {
  font-size: var(--labelFontSize) !important;
  min-height: var(--iconButtonHeight) !important;
}

:global .Select__control:hover {
  background: #fdfdfd;
}

:global .Select__control--is-focused {
  border-color: var(--accent2Color) !important;
  border-width: 1;
  box-shadow: var(--selectControlFocusShadow) !important;
}

:global .Select__control--menu-is-open {
  background: var(--surface6Color) !important;
}

:global .Select__option--is-focused {
  background-color: var(--selectFocusBackgroundColor) !important;
  color: var(--blackColor) !important;
}

:global .Select__option--is-selected {
  background-color: var(--accent2Color) !important;
  color: var(--whiteColor) !important;
  svg {
    color: var(--whiteColor);
  }
  label {
    color: var(--whiteColor);
    opacity: 0.6;
  }
}

:global .Select__clear-indicator,
:global .Select__dropdown-indicator {
  padding: 0 4px 0 4px !important;
}

:global .Select__menu {
  overflow: hidden;
}

:global .Select__menu-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

:global .Select__group {
  padding: 0 !important;
}

:global .Select__placeholder {
  color: var(--placeholderColor) !important;
  font-weight: 300;
}

.createHeader {
  padding-top: var(--insetMargin);
  padding-bottom: var(--insetMargin);
}

.addIcon {
  color: var(--iconColor);
  margin-right: var(--smallMargin);
}

.label {
  margin-left: var(--insetMargin) !important;
  margin-right: var(--insetMargin) !important;
}
