:root {
  --clearButtonWidth: 28px;
  --inputHeight: 30px;
  --rightSideIconWidth: 25px;
  --leftSideIconWidth: 27px;
}

.disabledField {
  /*background-color: var(--surface4Color) !important;*/
  background-color: var(--surface5Color) !important;
  opacity: 0.5;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding-right: 4px;
  padding-left: 5px;
  cursor: pointer;
  max-width: var(--leftSideIconWidth);
}

.iconWrapperRight {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding-right: var(--standardMargin);
  padding-left: var(--insetMargin);
  cursor: pointer;
  max-width: var(--rightSideIconWidth);
}
.iconWrapper svg {
  margin-top: 2px;
  margin-left: 5px;
  stroke: var(--surface3Color);
  fill: var(--surface3Color);
  color: var(--surface3Color);
  pointer-events: none;
}

.wrapper {
  display: flex;
  justify-content: flex-start;

  align-items: center;
  background: var(--inputBackground);
  color: var(--surface1Color);
  border: 1px solid var(--borderColor);
  font-size: var(--textInputFontSize);
  resize: none;
  transition: all var(--defaultSpeed) ease;
  transition-property: border-color, box-shadow;
  position: relative;
  padding: 0;
  border-radius: var(--insetMargin);
}

.focus {
  border-color: var(--accent1Color) !important;
  box-shadow:
    inset 0 1px 2px rgba(0, 0, 0, 0.075),
    0 0 5px rgba(69, 122, 187, 0.5);
}

.error {
  border-color: var(--errorColor) !important;
}

.input {
  height: var(--inputHeight);
  background: transparent;
  padding: 5px 5px;
  padding-left: 10px;
  margin: 0;
  border: none;
  font-size: var(--textInputFontSize);
  font-family: inherit;
  color: inherit;
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
}

.clearButton {
  width: var(--clearButtonWidth);
  cursor: pointer;
  padding: 0 5px;
  box-sizing: border-box;
  height: 100%;

  &:hover {
    stroke: var(--errorColor);
  }

  * {
    stroke-width: 5px;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.input::placeholder {
  color: var(--placeholderColor);
  font-weight: 300;
}

.hasRightIcon {
  width: calc(100% - var(--rightSideIconWidth));
  padding-right: 10px !important;
}

.hasLeftIcon {
  padding-left: 5px;
  width: calc(100% - var(--leftSideIconWidth));
}

.hasTwoIcons {
  width: calc(100% - var(--leftSideIconWidth) - var(--rightSideIconWidth));
}

.spinnerContainer {
  margin-right: var(--insetMargin);
}
