.baseContainer {
  height: calc(100% - 2px);
  width: 100%;
  box-sizing: border-box;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - var(--headerHeight));
  flex-direction: column;
}

.text {
  max-width: 400px;
  font-weight: 100;
  margin-bottom: 20px;
}

.errorMessage {
  margin: var(--standardMargin) 0;
}
