.container {
  display: flex;
  border-bottom: 1px solid var(--surface4Color);
  padding-bottom: var(--smallMargin);
  margin-top: var(--standardMargin);
  margin-bottom: var(--standardMargin);
  align-items: center;
}

.container:first-child {
  margin-top: 0;
}

.header {
  border: none;
  margin: 0;
  padding: 0;
}

.dashDivider {
  margin-left: var(--standardMargin);
  color: var(--textColor);
}

.statusContainer {
  display: flex;
  margin-left: var(--standardMargin);
  align-items: center;
  color: var(--textColor);
}

.statusBall {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--borderColor);
  margin-left: var(--smallMargin);

  &.isActive {
    background-color: var(--successColor);
  }
}
