.settingBody {
  font-size: 0.8rem;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.buttonSpace {
  margin-right: 20px;
}

.buttonSpace:last-child {
  margin-right: 0px;
}

.editorArea {
  width: 100%;
  box-sizing: border-box;
}

.gridFlow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: var(--standardMargin);
  justify-content: space-between;
  margin-bottom: 12px;
  gap: var(--standardMargin);
}

.gridItemHeader {
  padding-top: 10px;
  width: 100%;
  align-items: center;
}

.gridItemHeader:first-child {
  padding-top: 0px;
}

.gridItem {
  box-sizing: border-box;
  width: calc(50%-10px);
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gridItem label,
.doubleGridItem label,
.gridItemLabel {
  margin-right: var(--standardMargin);
}

.doubleGridItem {
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.doubleGridItem label {
  width: calc(25% - 34px);
}

.doubleGridItemValue {
  flex-grow: 1;
}

.settingDialog {
  width: 610px;
  max-height: 745px;
}
.settingDialogShort {
  width: 620px;
  max-height: 650px;
}

.gridInputField {
  height: 20px;
  padding-top: 0px;

  width: calc(55% - 11px);
  margin-right: 10px;
}

.gridInputField:disabled {
  background-color: gray;
}

.gridItem select {
  width: calc(55%-10px);
  margin-right: 10px;
}

.gridItem :global .Select__control {
  width: 150px;
}

.doubleGridItem :global .Select__control {
  width: 100%;
}

.gridInputField input {
  height: 20px;
}

.gridItemHeader input {
  margin-left: 20px;
  margin-bottom: 15px;
}

.descriptionEditor {
  width: 100%;
  height: 60px;
  margin-bottom: 12px;
}

.descriptionEditor textarea {
  resize: none;
  height: 100%;
}

.configHeader {
  margin-bottom: 10px;
}

.settingBody select {
  font-size: 12px;
}

.modbusHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.helpText {
  color: var(--labelColor);
}
