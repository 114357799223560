.enter {
  opacity: 0 !important;
  display: block !important;
}

.enterDone {
  display: block !important;
}

.enterActive {
  opacity: 1 !important;
  transition: opacity var(--defaultSpeed) !important;
}

.exit {
  opacity: 1 !important;
  display: block !important;
}

.exitActive {
  opacity: 0 !important;
  display: block !important;
  transition: opacity var(--defaultSpeed) !important;
}
