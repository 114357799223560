.dropdown {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;

  z-index: var(--modalZIndex);
  visibility: hidden;
  text-align: left;
}

.result {
  cursor: pointer;
  padding: var(--smallMargin) var(--standardMargin);
  font-size: var(--labelFontSize);
}

.result.selected {
  background-color: var(--surface4Color);
}

.result:hover {
  background-color: var(--surface5Color);
}

.result:hover:last-child,
.result.selected:hover:last-child {
  border-bottom-left-radius: var(--tableBorderRadius);
  border-bottom-right-radius: var(--tableBorderRadius);
}

.result:hover:first-child,
.result.selected:hover:first-child {
  border-top-left-radius: var(--tableBorderRadius);
  border-top-right-radius: var(--tableBorderRadius);
}

.result:not(:first-child) {
  border-top: 1px solid var(--borderColor);
}

.innerDropdown {
  background: white;
  box-shadow: var(--selectShadow);
  color: var(--blackColor) !important;
  width: auto;
  border-radius: var(--tableBorderRadius);

  display: flex;
  flex-direction: column;
}

.dropdownVisible {
  visibility: visible;
}

.highlight {
  background: var(--highlightSearchColor);
}
