.tagSelect {
  min-width: 220px;
}

.summary {
  display: flex;
  margin-bottom: var(--largeMargin);
}

.summaryText {
  font-size: 0.8em;
  margin-left: var(--smallMargin);
}

@media only screen and (max-width: 1472px) {
  .flexSpace {
    display: none;
  }
}

.summaryItem {
  min-height: 30px;
  display: flex;
  align-items: center;
}

.summaryItem + .summaryItem {
  margin-left: var(--largeMargin);
}

.summaryItemSpinner {
  margin-left: 8px;
}

.summaryItem label {
  font-size: var(--labelFontSize);
  color: var(--labelColor);
  font-weight: var(--labelWeight);
  margin-left: 0;
}

.dateFilter {
  display: flex;
  padding: 0 var(--standardMargin);
}

.clearDateButton {
  position: absolute;
  top: 4px;
  right: 27px;
  cursor: pointer;
}

.timeStampType {
  min-width: 100px;
  margin-right: 10px;
}

.exportButton {
  min-height: 33px;
}

.datePicker {
  border: 1px solid var(--borderColor);
  border-radius: var(--tableBorderRadius);
  background: var(--inputBackground);
}

.datePicker > div {
  border: 0 !important;
}

.dateTime {
  height: 100%;
  padding-top: var(--insetMargin);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.dateInput {
  padding-left: var(--standardMargin);
}
