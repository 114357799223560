.pageContainer {
  width: 100%;
  display: flex;
  flex-grow: 1;
  height: 1px;
  box-sizing: border-box;
  padding-left: var(--largeMargin);
  padding-right: var(--largeMargin);
}

.pageContainer.noSidePadding {
  padding-left: 0;
  padding-right: 0;
}

.treeContainer {
  width: 100%;
  display: flex;
  flex-grow: 1;
  height: 1px;
}

.innerContent.bottomMargin:last-child:after {
  height: var(--standardMargin);
  flex-shrink: 0;
  content: '';
}

.innerContent {
  min-height: 100%;
  width: 1px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-top: var(--largeMargin);
  box-sizing: border-box;
}

.innerContent.noTopPadding {
  padding-top: 0;
}

.noPadding {
  padding: 0;
}

.outerContainer {
  display: flex;
  height: 1px;
  flex-grow: 1;
}
.outerPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.toolbarDockParent {
  box-sizing: border-box;
  position: sticky;
  top: 0;
  left: 0;
  background: var(--surface5Color);
  z-index: 3;
  transition: box-shadow 0.3s ease-in-out;
}

.toolbarShadow {
  box-shadow: var(--navBarShadow);
}

.pageToolbar {
  padding-left: var(--largeMargin);
  padding-right: var(--largeMargin);
}
