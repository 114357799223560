.keyValueLine {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  gap: var(--standardMargin);
}

.keyValueColumn {
  display: flex;
  flex-direction: column;
  gap: var(--standardMargin);
}

.bottomMargin {
  margin-bottom: var(--standardMargin);
}

.bottomMargin:empty {
  margin-bottom: 0;
}

.keyValueActionContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.keyValueContainer {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: baseline;

  flex: 1 0 1px;
  width: 100%;
  height: auto !important;
}

.divider {
  border-bottom: var(--tableBorderStyle);
  padding-bottom: 10px;
}

.keyValueContainerHorizontal {
  flex-direction: row;
  align-items: center;
}

.keyValueContainerHorizontal > label {
  padding-bottom: 0;
}

.keyValueLine > .keyValueContainer {
  margin-bottom: 0;
}

.valueItem {
  height: auto;
  width: 100%;
  box-sizing: border-box;
  margin-right: var(--largeMargin);
}

.inputContainer {
  display: flex;
  width: 100%;
  flex-grow: 1;
}

.textInput {
  flex-grow: 1;
  width: 1px;
}

.keyValueContainer:last-child,
.keyValueContainer:only-child,
.valueItem:last-child,
.valueItem:only-child {
  margin-right: 0;
  margin-bottom: 0;
}

.keyValueLabel {
  display: block;
  padding-bottom: var(--smallMargin);
  margin-right: var(--standardMargin);
  flex-grow: 0;
  flex-shrink: 0;
  font-size: var(--labelFontSize);
  color: var(--labelColor);
  font-weight: var(--labelWeight);
}

.keyValueLabel:empty {
  padding-bottom: 0;
}

.keyValueLabelHorizontal {
  padding-bottom: 0;
}

.helpText {
  padding-top: var(--smallMargin);
  color: var(--labelColor);
  font-size: 0.8em;
  white-space: pre-line;
}

.horizontalHelpText {
  margin-bottom: var(--smallMargin);
}

.selectableInput {
  width: 100%;
}

.selectableInput svg {
  margin-right: var(--smallMargin);
}

.selectableInput * {
  cursor: pointer !important;
}

.errorSelect {
  :global .Select__control,
  :global .Select__control:hover {
    border-color: var(--errorColor);
  }
}

.selectableContent {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
}

.selectableContent > * + * {
  margin-left: var(--standardMargin);
}

.selectableIcon {
  flex-shrink: 0;
  font-weight: 800;
}

.error {
  border: 1px solid var(--errorColor) !important;
}

.keyValueButton {
  width: 100%;
}

.unit {
  border: 1px solid var(--borderColor);
  border-left: 0;
  display: flex;
  align-items: center;
  padding: 0 var(--standardMargin);
  background: var(--surface5Color);
  margin-left: calc(-1 * var(--largeMargin) - 2px);
  border-radius: var(--insetMargin);
  font-size: var(--textInputFontSize);
}

.keyValueContainerHorizontal > label {
  flex-basis: 0;
  margin-bottom: 0;
}

.keyValueContainerHorizontal > div {
  flex-basis: 0;
}

.centerVertically {
  align-items: center;
}

.errorNotice {
  margin-bottom: var(--standardMargin);
  font-size: 0.8em;
}
