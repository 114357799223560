.loading {
  box-sizing: border-box;
  z-index: 1;
  border-top: 6px solid rgba(0, 0, 0, 0.2);
  border-right: 6px solid rgba(0, 0, 0, 0.2);
  border-bottom: 6px solid rgba(0, 0, 0, 0.2);
  border-left: 6px solid;
  transform: translateZ(0);
  animation: loading var(--spinnerSpeed) infinite linear;
}

:global(.dark) .loading {
  border-top: 6px solid rgba(255, 255, 255, 0.2);
  border-right: 6px solid rgba(255, 255, 255, 0.2);
  border-bottom: 6px solid rgba(255, 255, 255, 0.2);
}

.loading,
.loading:after {
  border-radius: 50%;
}

.large {
  width: var(--spinnerLargeSize);
  height: var(--spinnerLargeSize);
}

.medium {
  width: var(--spinnerMediumSize);
  height: var(--spinnerMediumSize);
}

.small {
  width: var(--spinnerSmallSize);
  height: var(--spinnerSmallSize);
}

.loading.treeview {
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
}

.treeview {
  width: var(--spinnerTreeViewSize);
  height: var(--spinnerTreeViewSize);
}

.tiny {
  width: var(--spinnerTinySize);
  height: var(--spinnerTinySize);
}

.loading.tiny {
  border-width: 3px !important;
}

.loading.small {
  border-width: 5px !important;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.red {
  border-left-color: var(--primary1Color);
}

.white {
  border-left-color: var(--whiteColor);
}

.green {
  border-left-color: var(--accent1Color);
  border-top-color: rgba(0, 0, 0, 0.1);
  border-right-color: rgba(0, 0, 0, 0.1);
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.inline {
  height: 6px;
  width: 100%;
  display: flex;

  align-items: center;
}

.inline > div {
  width: 1%;
  flex-grow: 1;
  height: 3px;
  background-color: var(--surface3Color);
  opacity: 0.1;
  animation: inlineloading var(--spinnerSpeed) infinite linear;
}

.inline > div:first-child {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.inline > div:last-child {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.inline > div:nth-child(1) {
  animation-delay: 0.1s;
}

.inline > div:nth-child(2) {
  animation-delay: 0.2s;
}

.inline > div:nth-child(3) {
  animation-delay: 0.3s;
}

.inline > div:nth-child(4) {
  animation-delay: 0.4s;
}

@keyframes inlineloading {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0.1;
  }
}
