.notificationsDialog {
  max-width: 600px !important;
  max-height: 800px;
}

.notificationModal {
  padding: 0 !important;
  height: 600px;
}

.dialogContent {
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tableContainer {
  padding: var(--smallMargin);
  overflow-y: auto;
  flex-grow: 1;
}
