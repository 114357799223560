.noDataMessage {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: var(--largeMargin);
}

.noDataMessageIcon {
  max-width: 400px;
  margin-bottom: var(--largeMargin);
}

.noDataMessageTitle,
.noDataMessageMessage {
  border-bottom: none;
}

.noDataMessageMessage {
  text-align: center;
  line-height: 20px;
  margin-bottom: 0;
}
