.divider {
  width: 100%;
  border-bottom: 1px solid var(--borderColor);
  margin-bottom: var(--largeMargin);
  margin-top: var(--largeMargin);
}

.error {
  color: var(--errorColor);
}
:global(.Select__option--is-selected) .error {
  color: var(--whiteColor) !important;
}
