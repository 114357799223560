.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.container button {
  margin-top: var(--largeMargin);
}

.circle,
.totalCountCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid var(--accent1Color);
}

.circle {
  color: var(--accent1Color);
  position: relative;
}

.totalCountCircle {
  color: var(--blackColor);
  background: white;
  font-weight: 500;
  position: absolute;
  top: -5px;
  right: 0px;
  box-sizing: border-box;
}

.totalCountCircle:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.circle svg {
}

.severities {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--largeMargin);
  align-items: center;
  justify-content: center;
  min-height: 46px;
}

.spinner {
  flex-shrink: 0;
}

.severityContainer {
  flex-shrink: 0;
  padding: var(--smallMargin);
}

.severityBox {
  border: 2px solid var(--accent1Color);
  padding: var(--smallMargin) var(--largeMargin);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-weight: 500;
  font-size: 0.9em;
}

.severity-0 {
  border-color: var(--severity0Color);
}

.severity-1 {
  border-color: var(--severity1Color);
}

.severity-2 {
  border-color: var(--severity2Color);
}
