.container {
  background: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--blackColor);
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
}

.topToolbar {
  position: sticky;
  top: 0px;
  z-index: var(--navBarZIndex);
  margin: 0 auto;
  background: var(--accent1Color);
  box-sizing: border-box;
  box-shadow: var(--navBarShadow);
  width: 100%;
  padding: var(--standardMargin);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--whiteColor);
}

.topToolbar h2 {
  font-weight: bold;
  font-size: 1.7em;
}

.grid {
  max-width: 1400px;
}

.content {
  width: 100%;
  background: var(--surface5Color);
  padding: var(--standardMargin);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box {
  padding: var(--smallMargin);
}

.item {
  margin: 0 auto;
  width: 100%;
  margin-bottom: var(--hugeMargin);
}

.prop {
  font-weight: 600;
}

.composeInfo {
  margin-top: var(--standardMargin);
}

.class {
  font-size: 0.9em;
  padding-top: var(--smallMargin);
}

.description {
  white-space: pre-wrap;
}

.exampleDescription {
}

.examples {
  margin-top: var(--standardMargin);
  width: 100%;
}

.example {
  border-radius: var(--tableBorderRadius);
  border: 1px solid var(--borderColor);
  width: 100%;
}

.exampleInner {
  background: var(--whiteColor);
  border-bottom: 1px solid var(--borderColor);
  border-radius: var(--tableBorderRadius) var(--tableBorderRadius) 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--standardMargin);
}

.exampleSource {
  white-space: pre;
  color: var(--blackColor);
  font: 0.9em var(--monoFont);
  flex-grow: 1;
}

.example + .example {
  margin-top: var(--largeMargin);
}

.wrapWhitespace {
  white-space: pre-wrap;
  font-family: var(--monoFont);
  font-size: 0.9em !important;
}
