.staticDataTable {
  width: 100%;
  border-collapse: separate;
  font-size: var(--tableFontSize);
  min-height: 32px;
}

.staticDataTable td {
  padding: var(--tableVerticalMargin) 0 var(--tableVerticalMargin)
    var(--standardMargin);

  vertical-align: middle;
}

.staticDataTable td:last-child {
  padding-right: var(--standardMargin);
}

.staticDataTable tbody tr td {
  border-top: var(--tableBorderStyle);
}

.staticDataTable tbody tr td:last-child {
  border-right: var(--tableBorderStyle);
}

.staticDataTable tbody tr:first-child td:first-child {
  border-radius: var(--tableBorderRadius) 0 0 0;
  border-left: var(--tableBorderStyle);
}

.staticDataTable tbody tr:first-child td:last-child {
  border-radius: 0 var(--tableBorderRadius) 0 0;
}

.staticDataTable tbody tr:last-child td {
  border-bottom: var(--tableBorderStyle);
}

.staticDataTable tbody tr:first-child td:only-child {
  border-radius: var(--tableBorderRadius) var(--tableBorderRadius) 0px 0px;
  border-left: var(--tableBorderStyle);
}

.staticDataTable tbody tr td:first-child {
  border-left: var(--tableBorderStyle);
}

.staticDataTable tbody tr:last-child td:first-child {
  border-radius: 0 0 0 var(--tableBorderRadius);
  border-left: var(--tableBorderStyle);
}

.staticDataTable tbody tr:last-child td:last-child {
  border-radius: 0 0 var(--tableBorderRadius) 0;
}

.staticDataTable tbody tr:last-child td:only-child {
  border-radius: 0 0 var(--tableBorderRadius) var(--tableBorderRadius);
  border-left: var(--tableBorderStyle);
}

.staticDataTable tbody tr:only-child td:first-child {
  border-radius: var(--tableBorderRadius) 0 0 var(--tableBorderRadius);
  border-left: var(--tableBorderStyle);
}

.staticDataTable tbody tr:only-child td:last-child {
  border-radius: 0 var(--tableBorderRadius) var(--tableBorderRadius) 0;
}

.staticDataTable tbody tr:only-child td:only-child {
  border-radius: var(--tableBorderRadius);
}

.staticDataTable td label {
  font-weight: 600;
}

.staticDataTable td > a > svg,
.staticDataTable td > svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -2px;
}

.staticDataTable .spaceRow {
  height: 20px;
}

.staticDataTable tbody tr:nth-child(even) {
  background-color: var(--tableEvenRowColor);
}

.staticDataTable tbody tr:nth-child(odd) {
  background-color: var(--tableOddRowColor);
}

.staticDataTable tbody thead > tr:nth-child(even),
.staticDataTable tbody thead > tr:nth-child(odd) {
  background-color: transparent;
}

.staticDataTable th {
  text-align: left;
  padding: 0;
  padding-left: var(--standardMargin);
  padding-bottom: var(--smallMargin);
  font-size: var(--labelFontSize);
  color: var(--labelColor);
  font-weight: var(--labelWeight);
}

.staticDataTable > tbody > tr > td.cell {
}

.staticDataTable thead {
  border-top: 15px solid white;
}

.staticDataTable thead:first-child {
  border-top: 0px;
}

.staticDataTable a {
  color: var(--accent3Color);
  text-decoration: none;
  font-weight: 600;
}

.staticDataTable a svg {
  fill: var(--accent3Color);
  stroke: var(--accent3Color);
}

.staticDataTable a:hover {
  text-decoration: none;
  color: var(--textColor);
}

.staticDataTable a:hover > svg {
  fill: var(--iconColor);
  stroke: var(--iconColor);
}

.minWidthColumn {
  width: 1px;
  white-space: nowrap;
}

.selectedRow {
  background: var(--accent1Color) !important;
  color: var(--whiteColor);
  opacity: 1;
}

.selectedRow td {
  border-top-color: var(--accent1Color) !important;
}

.selectedRow td:first-child {
  border-left-color: var(--accent1Color) !important;
}

.selectedRow td:last-child {
  border-right-color: var(--accent1Color) !important;
}

.selectableRow {
  cursor: pointer;
}

:root {
  --hoverBorderColor: var(--surface3Color);
}

.selectableRow:hover td:first-child {
  border-left: 1px solid var(--hoverBorderColor);
}

.selectableRow:hover td {
  border-top: 1px solid var(--hoverBorderColor);
}

.selectableRow:hover td:last-child {
  border-right: 1px solid var(--hoverBorderColor);
}

.selectableRow:hover + .selectableRow td {
  border-top: 1px solid var(--hoverBorderColor);
}

.selectableRow:hover:last-child td {
  border-bottom: 1px solid var(--hoverBorderColor);
}

.selectableRow:hover:first-child td:first-child,
.selectableRow:hover:last-child td:first-child {
  border-left: 1px solid var(--hoverBorderColor);
}

.staticDataTable td > svg {
  color: var(--iconColor);
  margin-right: var(--smallMargin);
  margin-left: var(--insetMargin);
}

.footer {
  margin-top: var(--smallMargin);
  width: 100%;
  display: flex;
}

.footer button {
  margin-right: var(--largeMargin);
}

.footer button:last-child {
  margin-right: 0;
}

.staticDataTable tfoot > tr > td {
  padding-bottom: 0;
}

.loadingContainer {
  min-height: 50px;
}

.alignRight {
  justify-content: flex-end;
  text-align: right !important;
}

.alignCenter {
  text-align: center !important;
}

.alignLeft {
  text-align: left !important;
}

.noTextSelection {
  user-select: none;
}

.linkColumn {
  color: var(--accent3Color);
  font-weight: 600;
  svg {
    color: var(--accent3Color) !important;
  }
}

.selectableRow:hover {
  .linkColumn {
    color: var(--blackColor);
    svg {
      color: var(--blackColor);
    }
  }
}
