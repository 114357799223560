.details {
  display: flex;
  width: 100% !important;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
}

.detailsItem {
  overflow-y: hidden;
  box-sizing: border-box;
}

.detailsItem:first-child {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
}

.detailsItem:last-child {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-grow: 1;
}

.signalListSelector {
  flex: 1 1 auto;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
}

.treeList {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.signalList {
  flex: 1 1 auto;
  padding: 2px 0;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
}

.modal {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.selectedSignals {
  flex: 0 0 auto;
}

.modalBody {
  padding: 0px;
}

.modalResizer {
  height: 800px !important;
  max-height: 800px !important;
  max-width: 1200px !important;
}

@media (max-height: 840px) {
  .modalResizer {
    height: 95% !important;
    max-height: none !important;
  }
}

.filterContainer {
  width: calc(100%);
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 200px;
}

.filterBox {
  box-sizing: border-box;
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 3px;
  padding-right: 10px;
  height: 30px;
  width: 100%;
}

.signalTable {
  width: 100%;
  font-size: 0.925em;
}

.signalTable td {
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: middle;
  word-break: break-all;
}

.signalTable td label {
  font-weight: 600;
}

.signalTable td > a > svg,
.signalTable td > svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -2px;
}

.signalTable .spaceRow {
  height: 20px;
}

.signalTable tbody tr:nth-child(odd) {
  background-color: #fafafa;
}

.signalTable th {
  text-align: left;
  font-weight: bold;
  padding: 16px 0 16px 12px;
  background-color: white;
}

.signalTable thead {
  border-top: 15px solid white;
}

.signalTable thead:first-child {
  border-top: 0px;
}

.signalTable a {
  color: var(--accent3Color);
  text-decoration: none;
  font-weight: 600;
}

.signalTable a svg {
  fill: var(--accent3Color);
  stroke: var(--accent3Color);
}

.minWidthColumn {
  width: 1px;
  white-space: nowrap;
}

.rowSubtitle {
  opacity: 0.5;
  margin-left: 4px;
  align-items: center;
  display: flex;
}

.rowTitle {
  margin-bottom: 3px;
  margin-left: 4px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  align-items: center;
}

.loadingCell {
  min-height: 32px;
}

.box {
  background: var(--whiteColor);
  border-right: 1px solid var(--surface4Color);
  box-sizing: border-box;
  overflow: auto;
  padding: 0;
}

.box:last-child {
  border-right: 0;
  margin-right: 0;
}

.treeBox {
  padding-left: 0;
  padding-right: 0;
}

.failedToLoadDiv {
  padding: var(--standardMargin);
  box-sizing: border-box;
}

.selectedSignalsHeader {
  display: flex;
  justify-content: space-between;
  padding: 16px 12px 15px;
}

.selectedSignalsHeaderLabel {
  font-weight: bold;
}

.selectedSignalsHeaderButton {
}

.colorBar {
  flex-shrink: 0;
  margin-right: var(--standardMargin);
  vertical-align: middle;
  min-width: 13px;
  min-height: 13px;
  border-radius: 3px;
}

.buttonColumn {
  padding: 0 !important;
}

.buttonColumn:last-child {
  padding-right: var(--standardMargin) !important;
}

.buttonColumn button {
  margin: 0;
}
