.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: var(--standardMargin);
}

.nameInput {
  max-width: 300px;
}

.buttonCellContainer > div {
  float: right;
}
