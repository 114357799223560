.modalResizer {
  height: 100% !important;
  max-height: 800px !important;
  max-width: 1200px !important;
}

.modalBody {
  padding: 0px;
}
.signalSelector {
  height: 100%;
}
