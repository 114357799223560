.nameColumn {
  width: 180px;
}

.equipmentPropertyColumn {
  width: 100%;
}

.addressInput {
  width: 57px;
}

.alarmTemplateInput {
  width: 245px;
}

.nameInput {
  max-width: 500px;
}

.equipmentHeader td {
  background-color: #fafafa !important;
  padding-top: 16px;
  padding-bottom: 12px;
}

.equipmentHeaderMidColumn {
  width: 50%;
}

.equipmentNameColumn {
  font-weight: 500;
  padding-right: 20px;
}

.equipmentHeader td:not(:last-child) {
  border-right: 0 !important;
}

.equipmentHeader td label {
  font-weight: 600;
  font-size: var(--subtitleFontSize);
}

.equipmentSignalsList > td {
  padding: var(--largeMargin) 0 0 0;
  padding-right: 0 !important;
}

.equipmentSignalsList td > table tr:first-child > td {
  border-top: 0;
}

.equipmentSignalsList td > table tr:last-child > td {
  border-bottom: 0;
}

/* Ugly workarounds for nested table within table */

.equipmentSignalsList td > table > tbody > tr td {
  border: 0 !important;
  border-bottom: 1px solid #dadada !important;
  border-radius: 0 !important;
}

.equipmentSignalsList td > table > tbody > tr:first-child td {
  border-top: 1px solid #dadada !important;
}

.equipmentSignalsList td > table > tbody > tr:last-child td {
  border-bottom: 0 !important;
  border-bottom-left-radius: var(--tableBorderRadius) !important;
  border-bottom-right-radius: var(--tableBorderRadius) !important;
}

.equipmentTable {
  margin-bottom: 40px;
}

.equipmentHeader td label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.7em;
}

.splitSections {
  display: flex;
  width: 100%;
  margin-bottom: var(--largeMargin);
}

.splitSection {
  flex: 1;
  margin-right: 20px;
}

.splitSection:last-child {
  margin-right: 0px;
}

.mainSection {
  width: 100%;
  flex-grow: 1;
}

.saveSection {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
