:root {
  --toastMaxWidth: 500px;
  --toastHeight: 30px;
  --iconWidth: 20px;
  --toastWarningColor: #e68e00;
}

.toast {
  padding: var(--smallMargin) 0;
  color: var(--surface5Color);
  font-size: 1rem;
  max-width: var(--toastMaxWidth);
  min-height: var(--toastHeight);
  display: flex;
  align-items: center;
  user-select: none;
  border: 1px solid var(--borderColor);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: opacity var(--toastSpeed) ease-in;
  will-change: opacity;
  margin-bottom: 15px;
  clear: both;
  overflow: hidden;
  pointer-events: auto;
  background: white;
  border-radius: var(--tableBorderRadius);
}

.toast svg {
  fill: #fffff;
  stroke: #ffffff;
  width: 24px;
  height: 24px;
}

.successToast .icon {
  color: var(--accent1Color);
}

.errorToast .icon {
  color: var(--errorColor);
}

.warningToast .icon {
  color: var(--toastWarningColor);
}

.children {
  color: var(--blackColor);
  flex: 1 1 auto;
  padding: var(--smallMargin);
}

.icon {
  display: flex;
  padding-left: var(--standardMargin);
  padding-right: var(--smallMargin);
  padding-top: 0px;
  width: var(--iconWidth);

  align-items: center;
  justify-content: center;
  svg {
    display: block;
    margin: 0 auto;
    fill: white;
    stroke: white;
  }
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 44px;

  cursor: pointer;
  padding: 0 var(--standardMargin);

  svg {
    fill: var(--blackColor);
    stroke: var(--blackColor);
    width: 22px;
    height: 22px;
  }
}

.inlineToast {
  width: auto;
  height: auto;
  margin-bottom: 0;
  min-height: 0;
  padding: var(--smallMargin);
  background: var(--surface2Color);
  font-size: 0.85rem;
  z-index: var(--tooltipZIndex);
  background: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.inlineToast .closeButton {
  display: none;
}

.inlineToast .children {
  padding: 0;
}

.inlineToast .icon {
  padding: 0;
  width: var(--iconWidth);
  align-items: center;
  justify-content: center;
  background: transparent;
  svg {
    margin: 0 var(--smallMargin) 0 var(--insetMargin);
    height: auto;
  }
}
