.disabled {
  color: var(--surface3Color);
}

.addressColumn {
  width: 60px;
}

.saveColumn {
  text-align: right;
  padding-top: var(--standardMargin) !important;
  padding-right: 0 !important;
}

.editSignalField {
  width: 100%;
  margin-bottom: var(--standardMargin);
}
.editSignalButton {
  padding-left: var(--standardMargin);
  padding-right: var(--standardMargin);
  min-width: 50px;
}
