.cls1 {
  fill: none;
  stroke: inherit;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}

.cls1filled {
  fill: white;
  stroke: inherit;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}
