.modbusModalBody {
  background: white;
}

.modbusModal {
  max-height: 700px !important;
}

.modbusModalBody {
  background: white;
}
