.dividerVertical {
  border-left: var(--dividerWidth) solid var(--dividerColor);
  max-width: var(--dividerWidth);
  height: 100%;
}

.dividerHorizontal {
  border-top: var(--dividerWidth) solid var(--dividerColor);
  max-height: var(--dividerWidth);
  width: 100%;
}
