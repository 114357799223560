.cls1,
.cls2 {
  fill: none;
  stroke: inherit;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.cls1 {
  stroke-linecap: round;
}

.cls2 {
  stroke-linecap: square;
}
