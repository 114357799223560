.zIndex {
  z-index: var(--modalZIndex);
}

.window {
  border: var(--tableBorderStyle);
  border-radius: var(--tableBorderRadius);
  box-shadow: var(--overlayShadow);
}

:global {
  .twitter-picker[style] {
    box-shadow: none !important;
    border-radius: 0 !important;
    border-bottom-left-radius: var(--tableBorderRadius) !important;
    border-bottom-right-radius: var(--tableBorderRadius) !important;
    background: transparent !important;
  }

  .twitter-picker > div[style] {
    padding: 0 !important;
  }

  .swatches-picker > div > div[style] {
    border-radius: var(--tableBorderRadius) !important;
    box-shadow: none !important;
    background: transparent !important;
  }
  .swatches-picker > div > div > div[style] {
    overflow-y: auto !important;
  }
  .swatches-picker > div > div > div > div[style] {
    padding: 0 !important;
  }

  .swatches-picker > div[style] {
    display: block !important;
  }
}

.modal {
  max-width: none !important;
  width: auto !important;
}
