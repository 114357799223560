:root {
  --markerHoverColor: rgba(30, 162, 177, 0.6);
}

.marker {
  position: relative;
}

.cluster {
  width: 30px;
  height: 30px;
  background-color: var(--accent1Color);
  border-radius: 50%;
  display: flex;
  color: var(--whiteColor);
  font-weight: bold;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition:
    box-shadow var(--defaultSpeed) ease-in-out,
    opacity var(--defaultSpeed) ease-in-out;
  will-tranform: box-shadow;
  position: relative;
  top: -15px;
  left: -15px;
  box-shadow: 0 0 0 5px var(--markerHoverColor);

  &:hover {
    box-shadow: 0 0 0 10px var(--markerHoverColor);
  }
}

.location {
  position: relative;
  width: 25px;
  height: 40px;
  top: -40px;
  left: -12.5px;
  background-image: url('../Dashboard/panels/LocationMapPanel/marker-icon-teal.png');
  cursor: pointer;
}

.fade {
  opacity: 0.5;
}
