.box,
.child {
  padding: var(--plainBoxPadding);
  border-radius: var(--tableBorderRadius);
  box-sizing: border-box;
  overflow: auto;
}

.pageBox {
  padding: var(--standardMargin);
  width: 100%;
  flex-grow: 1;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.box,
.child {
  background: var(--whiteColor);
  border: var(--tableBorderStyle);
}

.headerBox {
  display: flex;
  flex-direction: column;
}

.header {
  flex: 0 0 auto;
}

.child {
  flex: 1 1 auto;
  background: var(--whiteColor);
  height: 100%;
}
