.column {
  display: flex;
  flex-direction: column;
  min-height: 49px;
  padding-left: var(--hugeMargin);
  padding-right: var(--hugeMargin);
  align-items: center;
  justify-content: flex-start;
  border-right: 1px solid var(--borderColor);
  width: 150px;
  flex-shrink: 1;
}

.column:first-child {
  padding-left: 0;
}

.column:last-child {
  padding-right: 0;
  border-right: 0;
}

.title {
  font-size: 0.9em;
  color: var(--surface3Color);
  margin-bottom: var(--smallMargin);
}

.subtitle {
  font-size: 0.9em;
  word-break: break-all;
}

.singleTitle {
  font-size: 15px;
  color: var(--surface3Color);
  margin-bottom: 0;
}

@media (max-width: 1300px) {
  .subtitle {
    font-size: 0.8em;
  }

  .singleTitle {
    font-size: 12px;
  }
}
