.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
}

.alarmContainer {
  flex: 1 1 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
