.zIndex {
  z-index: var(--modalZIndex);
}

.windowArrowContainer {
  width: 24px;
  height: 13px;
  z-index: 2;
  overflow: hidden;
  margin-top: -1px;
}

.windowArrow {
  width: 17px;
  height: 17px;
  transform: rotate(45deg);
  margin-top: -10px;
  margin-left: 4px;
}

.windowInnerContainer {
  user-select: none;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
}

.windowBody,
.windowArrow {
  background: var(--tooltipBackground);
  color: var(--whiteColor);
  cursor: pointer;
}

.windowBody {
  font-size: 0.95em;
  z-index: 1;
  border-radius: var(--tableBorderRadius);
}

.windowBody svg {
  width: 10px !important;
  margin-right: var(--iconTextMargin);
  color: var(--whiteColor) !important;
}

.window {
  z-index: var(--modalZIndex);
  font-size: var(--tableFontSize);
  display: flex;
  justify-content: center;
  transition: opacity var(--toastSpeed);
  cursor: pointer;
}

.windowButtonArea {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--smallMargin) var(--standardMargin);
  border-radius: var(--tableBorderRadius);
}

.windowInnerContainer:hover .windowButtonArea {
  background: var(--surface1Color);
}

.windowInnerContainer:hover .windowArrow {
  background: var(--surface1Color);
}

.additionalText {
  white-space: pre-wrap;
  padding: var(--smallMargin) var(--standardMargin);
}

.divider {
  border-bottom: 1px solid var(--surface3Color);
}

.enter {
  opacity: 0;
  will-change: opacity;
}

.enterActive {
  opacity: 1;
}

.exit {
  opacity: 0;
  will-change: opacity;
}

.exitActive {
  opacity: 0;
}
