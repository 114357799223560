.componentColumn {
  display: flex;

  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 !important;
  flex-direction: column;
  width: 100%;
}

.componentColumn > * + * {
  margin-bottom: 0 !important;
  margin-top: var(--hugeMargin) !important;
}

.center {
  align-items: center;
}
