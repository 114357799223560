.panelContainer {
  display: flex;
  background: var(--whiteColor);
  flex-direction: column;
  border-radius: var(--tableBorderRadius);
  border: var(--tableBorderStyle);
  box-shadow: var(--panelShadow);
  height: 100%;
}

:global(.dark) .panelContainer {
  background: var(--darkBackgroundColor);
  border: var(--darkBorderColor);
}

.panelContainer.showOnlyContent {
  border: 0;
  background: transparent;
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--modalZIndex);
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.titleContent {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: initial;
  flex-grow: 1;
}

.panelTitle {
  padding: var(--smallMargin) var(--standardMargin);
  border-bottom: 1px solid var(--surface4Color);
  display: flex;
  align-items: center;
}

:global(.dark) .panelTitle {
  border-bottom: 1px solid #444;
}

.panelTitle button {
  flex-shrink: 0;
}

.panelContent {
  padding: var(--standardMargin);
  height: 100%;
  overflow-y: auto;
}
