.provisioningModal {
  max-width: 960px !important;
  min-height: auto !important;
  max-height: 90vh !important;
}

.provisioningBody {
  font-size: 1em !important;
  line-height: inherit !important;
  background: white;
  min-height: auto !important;
  height: auto !important;
}

.provisioningContainer {
  width: 100%;
}

.deviceDetails {
  display: flex;
  flex-direction: column;
  background: var(--surface6Color);
  padding: var(--standardMargin);
  border: var(--tableBorderStyle);
  border-radius: var(--tableBorderRadius);
  align-items: flex-start;
}

.deviceDetailsActions button {
  flex-grow: 1;
}

.deviceDetailsActions {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: var(--standardMargin);
}

.section {
  width: 100%;
}
