.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.footer {
  flex-grow: 0;
  margin-bottom: var(--standardMargin);
}

.useAllAvailableHeight {
  flex-grow: 1;
}
