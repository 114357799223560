.selectUsersModal {
  max-width: 850px !important;
  max-height: 800px;
}

.modalBody {
  padding: 0 !important;
  overflow: hidden;
  background: white;
}

.dialogContent {
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
