.weekDay,
.weekDay:hover {
  background: var(--accent1Color) !important;
  color: var(--whiteColor) !important;
  border-radius: 0 !important;
}

.calendar > div {
  font-size: var(--tableFontSize);
  border-radius: 0;
  background: transparent;
  border: 0;
}
