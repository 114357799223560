.mappedTable {
  margin-bottom: var(--hugeMargin);
}

.equipmentInfo {
  flex-shrink: 0;
  margin-bottom: var(--standardMargin);
  display: flex;
}
.equipmentInfo > div {
  flex-grow: 1;
  min-height: auto;
  padding-bottom: 0;
}

.container * {
  vector-effect: non-scaling-stroke;
  shape-rendering: auto;
}

.container a {
  display: none;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: var(--hugeMargin);
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  flex-shrink: 0;
  position: relative;
}

.error {
  margin-bottom: var(--hugeMargin);
}

.select {
  margin-bottom: var(--smallMargin);
}
