:global {
  html,
  body {
    margin: 0;
    font-family: var(--appFont);
    font-size: 16px;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .highcharts-data-labels *,
  .highcharts-axis-labels text,
  .highcharts-xaxis-labels text {
    fill: var(--textColor) !important;
    color: var(--textColor) !important;
  }

  .highcharts-column-series > .highcharts-data-label > text,
  .highcharts-sankey-series > .highcharts-data-label > text {
    color: white !important;
    fill: white !important;
  }

  .highcharts-sankey-series > .highcharts-data-label > .highcharts-label-box {
    fill: transparent !important;
  }

  .dark {
    .highcharts-loading {
      background-color: var(--darkBackgroundColor) !important;
    }
    .highcharts-data-labels *,
    .highcharts-axis-labels text,
    .highcharts-xaxis-labels text {
      fill: var(--darkTextColor) !important;
      color: var(--darkTextColor) !important;
    }
  }

  .react-grid-item.cssTransforms {
    transition-property: none !important;
  }
  .animated .react-grid-item.cssTransforms {
    transition-property: transform !important;
  }

  sub {
    vertical-align: sub;
    font-size: smaller;
  }

  sup {
    vertical-align: super;
    font-size: smaller;
  }

  html,
  body {
    height: 100%;
  }

  body {
    min-height: 100%;
  }

  /* no outline for non-keyboard-inputs elements */
  *:focus {
    outline: none;
  }

  * {
    scrollbar-color: rgba(0, 0, 0, 0.18) #e5e5e5;
    scrollbar-width: thin;
  }

  body.user-is-tabbing *:focus {
    outline: 2px solid #7aacfe !important; /* for non-webkit browsers */
    outline: 5px auto -webkit-focus-ring-color !important;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.12);
    border: 0;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  ::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.6);
  }

  ::-webkit-scrollbar-track:active,
  ::-webkit-scrollbar-track:hover,
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border: 0;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  .react-grid-layout {
    position: relative;
    transition: height 200ms ease;
  }
  .react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
  }
  .react-grid-item.cssTransforms {
    transition-property: transform;
  }
  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }

  .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }

  .react-grid-item.react-grid-placeholder {
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  html {
    position: relative;
    min-height: 100%;
  }

  input,
  textarea,
  select,
  buttona {
    font-family: var(--appFont);
  }

  select {
    border-color: var(--borderColor);
    font-size: 12px;
  }

  a {
    color: var(--accent2Color);
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
  body {
    overflow: hidden;
    overflow-y: hidden;
  }

  .highcharts-container * {
    font-family: var(--appFont);
  }

  .highcharts-legend-series-active .highcharts-line-series path {
    opacity: 0.1;
  }

  .highcharts-legend-item path {
    opacity: 1 !important;
  }

  .highcharts-series-hover path {
    opacity: 1 !important;
  }
}

:global(#main),
.layout {
  width: 100%;
  height: 100%;
  background: var(--surface5Color);
}

:global(#main) {
  display: flex;
  justify-content: center;
  background-color: #fcfcfc;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

.withSidebar {
  display: flex;
  flex-direction: row;
}

.rightContent {
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  position: relative;
  transform: translateZ(0);
}

.sidebarContent {
  display: flex;
  width: 100%;
  height: 100%;
  transform: translateZ(0);
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
  transition: opacity 0.25s;
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
}

hr {
  width: 100%;
  border: 0;
  height: 1px;
  /* Set the hr color */
  color: var(--borderColor);
  background-color: var(--borderColor);
  margin-top: 15px;
  margin-bottom: 15px;
}
