.dateLabel {
  padding-top: 1px;
  margin-left: var(--smallMargin) !important;
  margin-right: var(--smallMargin) !important;
  font-size: 0.8em !important;
  font-weight: 400;
}

.dateInput {
  flex-grow: 1;
  border: 0;

  background: transparent !important;
}

.container {
  display: flex;
  align-items: center;
  width: 170px;
  box-sizing: border-box;
}

.expandingWidth {
  width: 100%;
}

.noPadding {
  padding-left: 0 !important;
}

.compact {
  height: 24px !important;
}
