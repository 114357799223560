.modalHeader {
  color: var(--textColor);
  background: var(--surface7Color);
  flex: 0 0 var(--modalHeaderHeight);
  margin: 0;
  padding: 5px var(--modalHeaderHeight) 5px 15px;
  font-size: 1.1rem;
  user-select: none;
  line-height: 20px;
  border-bottom: 1px solid var(--borderColor);
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  box-sizing: border-box;
  justify-content: space-between;
  font-weight: 500;
  border-radius: var(--modalCornerRadius) var(--modalCornerRadius) 0 0;
}

.modalHeaderInner {
  display: inline-flex;
  align-items: center;
}

@media (max-height: var(--modalBreakpointHeight)) {
  .modalHeader {
    flex: 0 0 var(--modalContractedHeaderHeight);
    height: var(--modalContractedHeaderHeight);
  }
}

.text {
  margin-left: 10px;
}

.titleIcon {
  stroke: var(--iconColor);
  fill: var(--iconColor);
  color: var(--iconColor);
}

.modalInnerHeader {
  background: var(--surface5Color);
  padding: var(--standardMargin);
  padding-bottom: var(--smallMargin);
  margin-left: calc(-1 * var(--largeMargin));
  margin-right: calc(-1 * var(--largeMargin));
  border-top: 1px solid var(--borderColor);
  border-bottom: 1px solid var(--borderColor);
  margin-bottom: var(--largeMargin);
  margin-top: var(--largeMargin);

  h1,
  h2,
  h3,
  h4,
  h5 {
    border-bottom: 0;
    margin-bottom: 0;
  }
}

.modalInnerHeader:first-child {
  margin-top: calc(-1 * var(--largeMargin));
  border-top: 0;
}
