.lighter {
  opacity: 0.25;
}

.small {
  width: 16px;
  height: 16px;
}

.large {
  width: 24px;
  height: 24px;
}

.rightMargin {
  margin-right: var(--smallMargin);
}

.leftMargin {
  margin-left: var(--smallMargin) !important;
  margin-right: 0 !important;
}
