.buttonSpace {
  margin-right: 20px;
}

.buttonSpace:last-child {
  margin-right: 0px;
}

.dialog {
  width: 520px;
  height: auto;
}

.inputField {
  margin-bottom: 18px;
}

.settingBody label {
  font-size: 0.8em;
  margin-right: 10px;
}

.spinner {
  width: 30px;
  height: 30px;
}
