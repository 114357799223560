.helpTooltipModal {
  padding-bottom: inherit;
}

.helpTooltipModalFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.linkToHelp {
  font-size: var(--baseSvgFontSize);
}
