.componentRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 !important;
}

.componentRow > * {
  margin: var(--smallMargin) var(--standardMargin) var(--smallMargin)
    var(--standardMargin);
}
