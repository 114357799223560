.valueContainer {
  display: flex;
  align-items: center;
}

.valueContainer div {
  margin-right: var(--smallMargin);
}

.inputWrapper {
  position: relative;
}
