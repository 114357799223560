.toastContainer {
  pointer-events: none;
}

.toastOuterContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  pointer-events: none;
  display: flex;
  align-items: center;
  padding-top: 33px;
  justify-content: flex-start;
  z-index: var(--toastZIndex);
  flex-direction: column;
  transform: translateZ(0);
  box-sizing: border-box;
}

.toastPadElement {
  width: 10px;
  height: 130px;
}
/*
 * Transition
 */

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  pointer-events: none;
}
