:root {
  --tableMargin: 5px;
  --borderSize: 1px;
  --smallerTableFontSize: 12px;
}

.headerCell {
  user-select: none;
  flex: 1 1 auto;
}

.row {
  display: flex;
  flex-direction: row;
  font-size: var(--tableFontSize);
  align-items: center;
  box-sizing: border-box;
  padding: 0 var(--smallMargin);
}

.row:nth-child(odd) {
  background: var(--tableOddRowColor);
}

.row:nth-child(even) {
  background: var(--tableEvenRowColor);
}

.whiteRow {
  background: #ffffff;
}

.headerRow:nth-child(odd),
.headerRow:nth-child(even) {
  background: transparent;
}

.row {
  border: var(--borderSize) solid var(--tableBorderColor);
  border-bottom: var(--borderSize) solid transparent;
}

.headerRow {
  height: auto;
}

.selectableRow {
  cursor: pointer;
}

.sectionHeaderRow {
  padding: var(--smallMargin);
  font-weight: 600;
}

.dataRow {
  &:hover {
    border: var(--borderSize) solid var(--surface3Color) !important;
    border-bottom: var(--borderSize) solid transparent !important;
  }
}

.row:last-child {
  border-bottom: var(--borderSize) solid var(--tableBorderColor);
}

.dataRow:last-child {
  &:hover {
    border-bottom: var(--borderSize) solid var(--surface3Color) !important;
  }
}

.selectedRow:first-child,
.row:first-child {
  border-radius: var(--tableBorderRadius) var(--tableBorderRadius) 0px 0px;
}

.selectedRow:last-child,
.row:last-child {
  border-radius: 0px 0px var(--tableBorderRadius) var(--tableBorderRadius);
}

.selectedRow:only-child,
.row:only-child {
  border-radius: var(--tableBorderRadius);
}

.dataRow:hover + .row {
  border-top: 1px solid var(--surface3Color) !important;
}

.headerRow {
  border: var(--borderSize) solid transparent;
  line-height: 1.2;
  &:hover {
    border: var(--borderSize) solid transparent;
  }
}

.header,
.headerCell {
  word-break: break-word;
  overflow-wrap: break-word;
  font-size: var(--labelFontSize);
  color: var(--labelColor);
  font-weight: var(--labelWeight);
}

.headerCell {
  display: flex;
  align-items: center;
  svg {
    flex-shrink: 0;
  }
}

.sortableHeaderCell {
  cursor: pointer;
}

.disableSort {
  pointer-events: none;
  cursor: default;
}

.rowColumn {
  padding: var(--smallMargin);
  box-sizing: border-box;
}

.rowColumn div > svg {
  color: var(--iconColor);
  margin-right: var(--iconTextMargin);
}

.sortColumn {
  /* Slightly smaller vertical padding order to accommodate sorting indicator without making header taller */
  padding: var(--tableMargin) var(--tableMargin);
}

.headerCell {
  overflow: hidden;
}

.linkColumn {
  color: var(--accent3Color);
  font-weight: 600;
}

.disableColumn {
  opacity: var(--disabledOpacity);
  color: var(--surface3Color);
  pointer-events: none;
}

.linkColumn div > svg {
  color: var(--accent3Color);
}

.selectableRow:hover {
  .linkColumn {
    color: var(--blackColor);

    svg {
      color: var(--blackColor);
    }
  }
}

.header > div {
  padding-left: var(--tableMargin);
  padding-right: var(--tableMargin);
  box-sizing: border-box;
}

.selectedRow,
.selectedRow:nth-child(odd),
.selectedRow:nth-child(even) {
  background: var(--accent1Color);
  color: var(--whiteColor);
  opacity: 1;
  svg {
    color: var(--whiteColor);
  }
}

.container {
  width: 100%;
}

.useAllAvailableHeight {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
}

.flexTable {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.flexTableContent {
  display: flex;
  flex-direction: column;
}

.footer {
  margin-top: var(--smallMargin);
  width: 100%;
  display: flex;
}

.footer button:last-child {
  margin-right: 0;
}

.alignRight {
  justify-content: flex-end;
}

.inlineRow {
  font-size: var(--tableInlineFontSize);
  border-radius: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.inlineRow:hover {
  border-left: 0 !important;
  border-right: 0 !important;
}

.smallerRow {
  font-size: var(--smallerTableFontSize);
}

.smallerSectionHeaderRow {
  font-weight: 500;
  font-size: var(--smallerTableFontSize);
}
