.title svg {
  color: var(--iconColor);
}

.subtitle {
  color: var(--surface3Color);
  font-size: var(--subtitleFontSize);
}

.subtitle.highlighted {
  color: var(--whiteColor);
}

.path {
  font-style: italic;
  font-weight: 600;
}
