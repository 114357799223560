.infoPath {
  fill: inherit;
}

.infoCircle {
  fill: none;
  stroke: inherit;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}
