.dialog {
  max-width: 1200px !important;
  max-height: 90vh !important;
}

@media (max-width: 1200px) {
  .dialog {
    max-width: 90vw;
  }
}
