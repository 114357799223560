.root {
  --processMapHoveringColor: rgba(135, 151, 164, 0.7);
}

.text {
  user-select: none;
}

.hovering {
  outline: 1px dashed var(--processMapHoveringColor);
  outline-offset: 0px;
}

.dragging {
  outline: 1px dashed var(--accent1Color);
  outline-offset: 0px;
}

.faded {
  opacity: 0.15;
}
