.container {
  display: flex;
  min-height: 59px;
}

.locationIcon {
  margin-right: var(--insetMargin);
  min-width: 22px;
  margin-top: 2px;
  margin-left: -4px;
}

.icon {
  height: 100%;
  display: flex;
  align-items: center;
}

.icon svg {
  margin-bottom: var(--smallMargin);
  margin-right: var(--insetMargin);
  min-width: 22px;
  margin-top: 2px;
  margin-left: -4px;
}

.locationArrowIcon {
  margin-left: var(--iconTextMargin);
  transition: transform var(--sidebarSpeed) ease-out;
}

.locationArrowIcon {
  height: 16px !important;
}

.locationArrowIcon.open {
  transform: rotate(90deg);
}

.expandingWidth {
  flex-grow: 1;
}

.top {
  display: flex;
}

.locationText {
  text-transform: none;
  min-height: 40px;
}

.buildingInfoPicker {
  user-select: none;
  cursor: pointer;
}

.buildingInfoPicker.open {
  color: var(--accent1Color);
}

.buildingInfoPicker:hover {
  color: var(--accent1ColorHover);
}

.tags {
  margin-left: var(--smallMargin);
}

.buildingInfo {
  text-transform: none;
  font-size: var(--tableFontSize);
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--tableBorderColor);
  cursor: pointer;
  box-sizing: border-box;
  padding-top: var(--standardMargin);
  box-sizing: border-box;
  padding-left: var(--largeMargin);
  padding-bottom: var(--smallMargin);
  font-size: var(--tableFontSize);
}

.buildingInfo:first-child {
  border-left: 0;
  padding-left: var(--largeMargin);
}

.buildingInfo > div {
  display: flex;
  align-items: center;
}

.icon > svg {
  margin-bottom: 2px;
  height: 13px;
}

.icon.large > svg {
  height: 24px !important;
  margin-bottom: -5px;
  margin-top: -5px;
}

.icon.large {
  height: 17px;
}

.subtitle {
  line-height: 1.1rem;
}

.properties {
  color: var(--labelColor);
  margin-left: var(--largeMargin);
  display: flex;
  gap: var(--largeMargin);
  font-size: var(--tableFontSize);
  box-sizing: border-box;
  margin-top: var(--insetMargin);
}

.properties > div {
  border-left: 1px solid var(--tableBorderColor);
  padding-left: var(--largeMargin);
}

.row:first-child {
}

.row {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--smallMargin);
}

.row label {
  font-weight: 600;
  font-size: 0.85em;
  margin-bottom: var(--insetMargin);
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
}

.title {
  font-weight: 600;
}
