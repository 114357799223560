.spacedItem {
  margin: 10px;
}

.wrapper {
  padding-left: 20px;
  padding-right: 20px;
}

.wrapper select {
  margin-right: 20px;
}

.md5Text {
  font-size: 0.8em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo,
    'Courier New', monospace;
}

.deploymentError {
  background-color: var(--primary1Color);
}

.deploymentPending {
  background-color: var(--pendingColor);
}

.deploymentSuccess {
  background-color: var(--successColor);
}

.spacedTable td {
  height: 30px;
}

.sectionSpacer {
  width: 100%;
  height: 6px;
}

.createConfigButton {
  margin-top: var(--standardMargin) !important;
}

.modbusModal {
  min-height: 700px !important;
}

.quadLabelColumn {
  width: 20%;
}

.quadValueColumn {
  width: 30%;
}

.errorText {
  color: var(--errorColor);
}

.wrappingCell {
  overflow: visible !important;
  white-space: normal !important;
}

.validationError {
  color: var(--primary2Color) !important;
}
