.cls1,
.cls2,
.cls3 {
  fill: none;
  stroke: inherit;
  stroke-width: 2px;
}

.cls1,
.cls2 {
  stroke-miterlimit: 10;
}

.cls2,
.cls3 {
  stroke-linecap: round;
}

.cls3 {
  stroke-linejoin: round;
}
