.container {
  width: 100%;
  height: 100%;
  cursor: default;
  position: relative;
}

.searchField {
  position: absolute;
  top: var(--smallMargin);
  left: var(--smallMargin);
  width: 250px;
  z-index: 1;
}
