.modalResizer {
  height: 800px !important;
  max-height: 800px !important;
  max-width: 800px !important;
}

@media (max-height: 840px) {
  .modalResizer {
    height: 95% !important;
    max-height: none !important;
  }
}

.modalBody {
  padding: 0;
}

.modalBody > div {
  display: flex;
  flex-direction: column;
}
