.borderLeft {
  border-left: var(--tableBorderStyle);
}

.body {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.signalsSection {
  flex-grow: 1;
  margin-top: var(--insetMargin);
}
