.newConnection {
  color: var(--primary1Color);
}

.infoText {
  color: var(--surface3Color);
  font-style: italic;
  margin-top: 15px;
  margin-bottom: 15px;
}

.configContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.selectContainer {
  flex: 1;
}

.topLabel {
  flex-shrink: 0;
  width: 160px;
  margin-right: 20px;
}

.topValue {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.configBlock {
  margin-top: 15px;
  margin-bottom: 15px;
}

.configBlock:first-child {
  margin-top: 0px;
}
.modal {
  max-height: 800px !important;
  max-width: 720px !important;
}

.modalBody {
  background: white;
}

.equipmentTable {
  margin-bottom: var(--largeMargin);
}
