.container {
  display: flex;
  align-items: flex-start;
}

.menu {
  flex: 0 0 400px;
  flex-shrink: 1;
  min-width: 250px;
  background: white;
  margin-right: var(--standardMargin);
  padding: 0;
}

.content {
  flex-grow: 1;
  padding: 0 var(--largeMargin) var(--largeMargin);
}

.boxContent {
  max-width: 700px;
  flex-grow: 1;
}

.missingFileMessage {
  margin-top: var(--standardMargin);
}
