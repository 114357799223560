.nameWrapper {
  cursor: pointer;
}

.alarmSeverities {
  display: flex;
  flex-shrink: 0;
  margin-left: var(--smallMargin);
  justify-content: flex-end;
  align-items: center;

  &:hover {
    text-decoration: none;

    span {
      filter: brightness(90%);
    }
  }

  & > .arrow {
    width: var(--baseSvgFontSize);
    margin-left: var(--smallMargin);

    & > svg {
      margin-right: 0;
    }
  }
}

svg.noSeveritiesIcon {
  width: 18px !important;
  height: 18px;
}
