.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
}

.mainSignal {
  color: var(--whiteColor);
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 50%;
}

.value {
  box-sizing: border-box;
  font-weight: 600;
}

.loading {
  font-size: 16px;
}
