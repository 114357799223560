.dropdown {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  z-index: var(--modalZIndex);
  visibility: hidden;
  text-align: left;
}

.rightsideOptionIcon > svg,
.icon > svg {
  font-size: 0.9em;
  color: var(--blackColor);
}
.icon > svg {
  margin: 0 !important;
}

.button {
  padding-left: var(--iconTextMargin);
}

.button:focus {
  background: var(--surface6Color);
  border-color: var(--accent2Color) !important;
  box-shadow: var(--selectControlFocusShadow) !important;
}

.rightSideIcon {
  transition: color var(--sidebarSpeed) ease-out;
  margin-left: var(--standardMargin) !important;
  margin-right: calc(-1 * var(--smallMargin)) !important;

  color: var(--selectControlInactiveColor) !important;

  padding-left: var(--smallMargin) !important;
  border-left: var(--tableBorderStyle);
}

.rightSideIcon.open {
  color: var(--selectControlActiveColor) !important;
}

.innerDropdown {
  background: white;
  box-shadow: var(--selectShadow);
  color: var(--blackColor) !important;
  width: auto;
  border-radius: var(--tableBorderRadius);
}

.label {
  line-height: 14.4px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.icon {
  margin-left: var(--smallMargin);
  margin-right: var(--smallMargin);
}

.subtitle {
  color: var(--surface3Color);
  margin-top: var(--insetMargin);
}

.disabledItem {
  pointer-events: none;
  background: white;
  opacity: 0.5;
}

.disableWrap {
  white-space: nowrap;
}

.dropdownItem {
  display: flex;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid var(--borderColor);
}

.dropdownItem > div {
  min-width: 24px;
}

.space {
  flex-grow: 1;
}

.dropdownItem.hasAction {
  cursor: pointer;
}

.dropdownItem:hover {
  background: var(--surface5Color);
}

.dropdownItem:first-child {
  border-radius: var(--tableBorderRadius) var(--tableBorderRadius) 0 0;
}

.dropdownItem:last-child {
  border-radius: 0 0 var(--tableBorderRadius) var(--tableBorderRadius);
}

.dropdownItem:only-child {
  border-radius: var(--tableBorderRadius) var(--tableBorderRadius)
    var(--tableBorderRadius) var(--tableBorderRadius);
}

.dropdownItem:last-child {
  border-bottom: 0;
}

.dropdownVisible {
  visibility: visible;
}

.popper {
  z-index: var(--modalZIndex);
}

.closeFooter {
  padding: var(--smallMargin);
  display: flex;
  justify-content: flex-end;
}

.fileInput {
  display: none;
}
