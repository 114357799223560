.wideInputField {
  width: 100%;
}

.minWidthColumn {
  width: 1px;
  white-space: nowrap;
}

.smallSelector {
  font-size: 0.9em;
  width: 200px;
}

.smallSelector {
  font-size: 0.9em;
  width: 100%;
}

.smallSelector {
  :global .Select-control {
    height: 30px;
  }
  :global .Select-placeholder {
    margin-top: 4px;
    line-height: 20px;
  }
  :global .Select-value {
    line-height: 26px !important;
  }

  :global .Select-value-label {
    line-height: 26px;
  }

  :global .Select-input {
    height: 26px;
  }
}
