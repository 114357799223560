.modalText,
.comment {
  width: 100%;
}

.comment {
  margin-top: 10px;
}

.button {
  width: 100%;
  svg {
    fill: var(--whiteColor);
    stroke: var(--whiteColor);
  }
}

.icon {
  font-size: 0;
}
