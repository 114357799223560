.severityBox {
  padding: var(--insetMargin) var(--smallMargin);
  margin-right: var(--insetMargin);
  display: flex;
  justify-content: center;
  border-radius: 16px;
  font-weight: 500;
  font-size: 0.9em;
  color: var(--whiteColor);
}

.severity-0 {
  background-color: var(--severity0Color);
}

.severity-1 {
  background-color: var(--severity1Color);
}

.severity-2 {
  background-color: var(--severity2Color);
}
