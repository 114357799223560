.dateInput {
}

.footer,
.dateInput {
  border-top: 1px solid var(--borderColor);
  padding: var(--standardMargin);
}

.footer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background: var(--surface5Color);
}

.button {
  display: flex;
  min-width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.button > div {
  display: flex;
  flex-grow: 1;
}

.radioButton {
  margin-right: var(--smallMargin);
}

.button label {
  margin-right: var(--smallMargin);
}

.calendarWrapper {
  background: var(--surface6Color);
  min-width: 300px;
  padding: var(--smallMargin) 0;
  box-sizing: border-box;
  border-bottom: 1px solid var(--borderColor);
}

.calendarWrapper :global .rdtYear,
.calendarWrapper :global .rdtMonth {
  height: 35px;
}

.calendarWrapper :global .rdtActive {
  border-radius: var(--tableBorderRadius);
}

.menuHeader {
  box-sizing: border-box;
  font-size: 0.9em;
  font-weight: 500;
}

.optionsMenu > div {
  min-width: 300px;
}
