/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src:
    local('Open Sans Light'),
    local('OpenSans-Light'),
    url('./open-sans-v13-latin-300.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+ */ url('./open-sans-v13-latin-300.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src:
    local('Open Sans'),
    local('OpenSans'),
    url('./open-sans-v13-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+ */ url('./open-sans-v13-latin-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src:
    local('Open Sans Italic'),
    local('OpenSans-Italic'),
    url('./open-sans-v13-latin-italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+ */ url('./open-sans-v13-latin-italic.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src:
    local('Open Sans Bold'),
    local('OpenSans-Bold'),
    url('./open-sans-v13-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+ */ url('./open-sans-v13-latin-700.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src:
    local('Open Sans SemiBold'),
    local('OpenSans-SemiBold'),
    url('./open-sans-v13-latin-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+ */ url('./open-sans-v13-latin-600.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
