.editSignalButton {
  padding-left: var(--standardMargin);
  padding-right: var(--standardMargin);
  min-width: 50px;
}

.editRow {
  display: flex;
  align-items: center;
}

.editSwitch {
  padding: var(--smallMargin) 0 var(--smallMargin) 0;
}

.hoverFlex {
  display: flex;
  align-items: center;
}

.hoverItem {
  flex-grow: 1;
  width: 1px;
}

@media (max-width: 1300px) {
  .hoverFlex {
    display: inline;
  }

  .hoverItem {
    display: none;
  }
}
