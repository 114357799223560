.editParentsModal {
  max-width: 450px !important;
  max-height: 550px !important;
}

.editParentBody {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0px 0px 10px 0px !important;
}
