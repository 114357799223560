.cls1 {
  fill: none;
  stroke: inherit;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.cls2 {
  fill: inherit;
  stroke: none;
}
