:global .dataTableAnimation-enter > div {
  opacity: 0.2;
  transform: scale(1.02);
}

:global .dataTableAnimation-enter-active > div {
  opacity: 1;
  transform: scale(1);
  transition: all var(--dataTableSpeed) ease-in;
}

:global .dataTableAnimation-exit > div {
  opacity: 1;
}

:global .dataTableAnimation-exit-active > div {
  opacity: 0;
  transition: all var(--dataTableSpeed) ease-out;
}

:global .dataTableRowAnimation-enter > div {
  opacity: 0.2;
  transform: scale(1.02);
}

:global .dataTableRowAnimation-enter-active > div {
  opacity: 1;
  transform: scale(1);
  transition: all var(--dataTableSpeed) ease-in;
}
