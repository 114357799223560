.segmentControl,
.select {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
}

.select {
  width: 300px;
}

.visible {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}

.container {
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  min-height: 40px;
}

.segmentContainer {
  position: absolute;
}
