.tagContainer {
  display: inline-block;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--insetMargin));
}

.tagContainer div {
  margin-left: 0 !important;
  margin-right: var(--insetMargin);
  margin-top: var(--insetMargin);
}
