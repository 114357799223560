.helpButton {
  width: 31px;
  height: 31px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--accent1Color);
  color: var(--accent1Color);
  border-radius: 50%;

  &.small {
    width: 20px;
    height: 20px;
    border-width: 1px;
  }

  &.light {
    color: var(--iconColor);
    border-color: var(--iconColor);
  }
}
