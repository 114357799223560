.portal {
  pointer-events: none;
  z-index: var(--tooltipZIndex);
  position: absolute;
}

.calculatingDirection {
  visibility: hidden;
}

.tooltip {
  position: relative;
  z-index: var(--tooltipZIndex);
  transition: opacity var(--tooltipSpeed) ease-in;
  height: auto;
}

.tooltip span {
  position: absolute;
  padding: 6px 8px;
  border-radius: 3px;
  text-align: center;
  white-space: pre;
  word-wrap: break-word;
  font-size: 0.75rem;
  font-family: var(--appFont);
  color: var(--whiteColor);
  background: var(--tooltipBackground);
}

.tooltip:before {
  position: absolute;
  z-index: var(--tooltipZIndex);
  width: 0;
  height: 0;
  border: 5px solid transparent;
  content: '';
  pointer-events: none;
  color: var(--tooltipBackground);
}

.tooltip:before,
.tooltip span {
  display: inline-block;
  text-decoration: none;
}

.tooltip-s span,
.tooltip-se span,
.tooltip-sw span {
  top: 0px;
  right: 50%;
  margin-top: 5px;
}

.tooltip-s:before,
.tooltip-se:before,
.tooltip-sw:before {
  top: -5px;
  border-bottom-color: var(--tooltipBackground);
}

.tooltip-s:before {
  right: 50%;
  margin-right: -5px;
}

.tooltip-se span {
  right: 0px;
  left: auto;
}

.tooltip-sw span {
  left: 0px;
  right: auto;
}

.tooltip-sw:before {
  left: 15px;
}

.tooltip-se:before {
  right: 15px;
  left: auto;
}

.tooltip-n span,
.tooltip-ne span,
.tooltip-nw span {
  right: 50%;
  bottom: 100%;
  margin-bottom: 5px;
}

.tooltip-n:before,
.tooltip-ne:before,
.tooltip-nw:before {
  top: -5px;
  bottom: auto;
  margin-right: -5px;
  border-top-color: var(--tooltipBackground);
}

.tooltip-n:before {
  right: 50%;
}

.tooltip-nw:before {
  left: 15px;
  margin-right: 0px;
}

.tooltip-ne:before {
  right: 15px;
  margin-right: 0px;
}

.tooltip-ne span {
  right: 0;
  left: auto;
}

.tooltip-nw span {
  left: 0px;
  right: auto;
}

.tooltip-s span,
.tooltip-n span {
  transform: translateX(50%);
}

.tooltip-w span {
  right: 100%;
  bottom: 50%;
  margin-right: 5px;
  transform: translateY(50%);
}

.tooltip-w:before {
  top: 50%;
  bottom: 50%;
  left: -5px;
  margin-top: -5px;
  border-left-color: var(--tooltipBackground);
}

.tooltip-e span {
  bottom: 50%;
  left: 100%;
  margin-left: 5px;
  transform: translateY(50%);
}

.tooltip-e:before {
  top: 50%;
  right: -5px;
  bottom: 50%;
  margin-top: -5px;
  border-right-color: var(--tooltipBackground);
}

.multiline span {
  display: table-cell;
  max-width: 500px;
  width: max-content;
  border-collapse: separate;
  white-space: pre-line;
  word-wrap: normal;
  word-break: break-word;
  line-height: 1.25;
}

.multiline.tooltip-s span,
.multiline.tooltip-n span {
  right: auto;
  left: 50%;
  transform: translateX(-50%);
}

.multiline.tooltip-w span,
.multiline.tooltip-e span {
  right: 100%;
}

@media screen and (min-width: 0 \0) {
  .multiline span {
    width: 250px;
  }
}

.tooltip-sticky:before,
.tooltip-sticky span {
  display: inline-block;
}

.tooltip-sticky.multiline span {
  display: table-cell;
}

/*
 * Transition
 */

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
}

.exit {
  opacity: 0;
}

.exitActive {
  opacity: 0;
}
