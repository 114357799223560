.buttonContainer {
  font-size: 15px;
  color: var(--iconColor);
  font-weight: 300;
  width: 100%;
  border-bottom: 1px solid var(--borderColor);
  margin-bottom: var(--standardMargin);
  display: flex;
}

.flexibleSpace {
  flex-grow: 1;
}

.section.collapsed .buttonContainer {
  margin-bottom: var(--insetMargin);
}

.wrapped {
  padding: var(--smallMargin) var(--standardMargin);
  background: var(--surface6Color);
  border: 1px solid var(--borderColor);
  border-radius: var(--tableBorderRadius);
  margin-bottom: var(--standardMargin);
}

.iconButton svg {
  font-size: 12px;
  min-width: 19px;
}

.iconButton:hover {
  background: transparent;
}

.iconButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0;

  svg {
    margin-left: 0;
    margin-top: 1px;
    margin-right: var(--smallMargin);
  }
}

.nestedSection {
  width: 100%;
  box-sizing: border-box;
  padding: var(--standardMargin);
  border: 1px solid var(--borderColor);
  background: var(--whiteColor);
  margin-bottom: var(--standardMargin);
  border-radius: var(--tableBorderRadius);
}
