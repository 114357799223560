.tag {
  font-size: 12px;
  background: var(--accent1Color);
  color: var(--whiteColor);
  padding: 3px 5px;
  border-radius: 3px;
}

.tag + .tag {
  margin-left: var(--smallMargin);
}
