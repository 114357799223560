.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.chart {
  flex-grow: 1;
  height: 1px;
  min-height: 0;
}
