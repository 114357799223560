.cls1 {
  fill: inherit;
}

.cls1,
.cls2 {
  stroke: inherit;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3;
}

.cls2 {
  fill: none;
}
