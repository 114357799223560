.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  user-select: none;
}

.checkboxCheckmark {
  display: flex;
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid var(--borderColor);
  font-size: 12.8px !important;
  background: white !important;
  box-sizing: border-box;
  svg {
    color: var(--accent2Color) !important;
    margin: 0 !important;
  }
}

.checkboxCheckmark.compact {
  width: 16px;
  height: 16px;
  font-size: 9px !important;
}

.marginRight {
  margin-right: var(--smallMargin);
}

.checkbox > *:last-child {
  font-weight: 400;
  color: var(--iconColor);
}

.icon {
  opacity: 0;
  transition: opacity 0.25s;
}

.visibleIcon {
  opacity: 1;
}

.partiallyVisibleIcon {
  opacity: 0.5;
}

.disabled {
  opacity: var(--disabledOpacity);
}
.disabled .checkboxCheckmark {
  background: var(--disabledBackgroundColor);
}

.constrainSize {
  width: 22px;
  height: 22px;

  flex-shrink: 0;
  flex-grow: 0;
}

.constrainSize.compact {
  width: 20px;
  height: 20px;
}
