.notice {
  font-size: 0.9rem;
  color: var(--surface1Color);
}

.notice ul {
  margin-top: 15px;
}

.notice ul {
  list-style-type: circle;
  list-style-position: inside;
  margin-left: 2px;
}

.errorNotice {
  color: var(--errorColor);
}
