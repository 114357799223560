.loadingContainer {
  position: relative;
  transition: filter var(--defaultSpeed) ease-in-out;
}

.loadingContainerWithSpinner {
  min-height: 100px;
}

.loadingContainer,
.children {
  width: 100%;
  display: inherit;
  flex: inherit;
  flex-direction: inherit;
  flex-grow: 1;
}

.isLoading {
  filter: blur(2px);
  pointer-events: none;
}

.noBlurLoading {
  pointer-events: none;
  background: white;
  opacity: 0.5;
}

.spinnerWrapper {
  position: absolute;
  top: 50%;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  height: 0;
  z-index: 2;

  .spinner {
    display: inline-block;
  }
}

.longLoad {
  font-size: 0.8rem;
  opacity: 0.5;
  margin: 15px 0;
}
