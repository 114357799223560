.radioButtonContainer {
  display: flex;
  align-items: baseline;
}

.radioButtonLabel {
  margin-right: var(--smallMargin);
}

.radioButtonLabel,
.radioButton {
  cursor: pointer;
}
