.debugLogin {
  display: flex;
  min-width: 250px;
  flex-direction: column;
  justify-content: center;
}

.debugLogin div {
  margin-right: 0;
}
