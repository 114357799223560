:root {
  --barWidth: 5px;
  --barHeight: 20px;
}

.tooltip {
  display: inline-block;
}

.barsContainer {
  display: flex;
  height: var(--barHeight);
  margin-top: var(--barWidth);
  margin-bottom: var(--barWidth);
}

.label {
  margin-top: var(--smallMargin);
  font-size: var(--labelFontSize);
}

.bar {
  width: var(--barWidth);
  background-color: var(--placeholderColor);
  margin-right: 3px;
  align-self: baseline;
}

.barIsActive {
  background-color: var(--accent1Color);
}
