.dialog {
  min-height: 750px !important;
  max-height: 750px !important;
  min-width: 640px;
}

@media (max-height: 800px) {
  .dialog {
    min-height: 80vh !important;
    max-height: 80vh !important;
  }
}

.disabled {
  opacity: 0.35;
  pointer-events: none;
  filter: grayscale(100%);
  transition: opacity 0.5s;
}
