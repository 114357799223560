.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.selectContainer {
  position: absolute;
  top: var(--smallMargin);
  left: var(--smallMargin);
  z-index: 1;
}

.centerToTargetButton {
  position: absolute;
  bottom: 115px;
  right: 10px;
  height: 40px;
  width: 40px;
  z-index: 1;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 2px;
  background-color: white;
}

.select {
  width: 300px;
}
