.rowCell {
  box-sizing: border-box;
  min-width: 0;
  height: 100%;
  line-height: initial;
  overflow-wrap: break-word;
  word-break: break-word;
}

.truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rowCell td > a > svg,
.rowCell td > svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -2px;
}

.rowCell a {
  color: var(--accent3Color);
  text-decoration: none;
  font-weight: 600;
}

.rowCell a svg {
  fill: var(--accent3Color);
  stroke: var(--accent3Color);
  color: var(--accent3Color);
}
.rowCell a:hover {
  text-decoration: none;
  color: var(--textColor);
}

.rowCell a:hover > svg {
  fill: var(--iconColor);
  stroke: var(--iconColor);
  color: var(--iconColor);
}

.rowCell.selected a {
  color: white !important;
}
