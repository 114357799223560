.settingsContainer {
  display: inline-block;
  position: absolute;
  bottom: var(--standardMargin);
}

.settings {
  background: var(--surface7Color);
  border: 1px solid var(--borderColor);
  padding: var(--standardMargin);
  border-radius: 0 var(--tableBorderRadius) var(--tableBorderRadius) 0;
  cursor: default;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  clip-path: inset(-30px -30px -30px 0px);
  display: flex;
  flex-direction: column;
}

.settings button {
  width: 100%;
}

.select {
  min-width: 320px;
}
