.wrapper select {
  font-size: 0.6em;
}

.title {
  padding-left: 20px;
  padding-right: 20px;
}

.map {
  z-index: 0;
  height: 100%;
  flex-grow: 1;
}

.spacedItem {
  margin-bottom: 10px;
}

.spacer {
  height: 20px;
  clear: both;
  width: 1px;
}

.fixedInput * {
  cursor: pointer !important;
}

.section {
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}

.buttonSet {
  text-align: right;
}

.meteorologyTable {
  padding-top: 10px;
}

.staticUserTable {
  width: 100%;
  margin-bottom: var(--largeMargin);
  user-select: none;
}

.staticUserTable thead > tr > th {
  background: transparent !important;
  padding-top: var(--smallMargin);
  vertical-align: middle;
}

.staticUserTable td {
  word-break: break-all;
}

.messageBody {
  font-size: 0.85rem;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.dialogContent {
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.error {
  background: var(--errorColor);
  padding: 10px;
  margin-bottom: 10px;
  color: white;
  border-radius: 4px;
}

.treeContainer {
  min-height: 100% !important;
  padding: 10px;
  background: transparent !important;
  box-sizing: border-box;
}

.mapArea {
  flex: 1;
  margin-left: var(--largeMargin);
  height: 100%;
}

.locationForm {
  max-width: 650px;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 15px 15px;
  background: #fdfdfd;
  border: 1px solid var(--surface4Color);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: var(--standardMargin);
  width: 1px;
  min-width: 300px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--largeMargin);
  align-items: flex-start;
}

.buttonHeader {
  margin-top: 30px;
}

.buttonRow {
  display: flex;
}

.inputItem,
.infoValue {
  flex: 1;
}

.infoValue {
  font-size: 0.9em;
}

.infoTable {
  width: 100%;
}

.fullHeight {
  height: 100%;
  margin-bottom: 0px;
}

.infoTable button {
  width: 100%;
}

.searchField {
  width: 100%;
  box-sizing: border-box;
}

.searchContainer {
  background: var(--surface5Color);
  padding: var(--standardMargin);
  border: 1px solid var(--borderColor);
  flex-shrink: 0;
  box-sizing: border-box;
  flex-grow: 0;
}

.fixedContainer {
  display: flex;
}

.tableContainer {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0px;
  margin-bottom: var(--standardMargin);
  box-sizing: border-box;
}

.padContent {
  padding: var(--standardMargin);
}

.addAllCheckbox {
  margin-left: 2px;
}

.noUsersText {
  padding-top: var(--standardMargin);
}

.infoColumn {
  min-width: 95px;
}

.subpageSelector {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-bottom: calc(var(--standardMargin) * 2);
}

.inlineMap {
  position: relative;
  width: 100%;
  height: 156px;
  z-index: 0;
  box-sizing: border-box;
  border: 1px solid var(--surface3Color);
  cursor: default;
}

.inlineMapOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}

.buttonArea {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: var(--standardMargin);
  border-top: 1px solid var(--borderColor);
  padding-top: var(--standardMargin);
}
