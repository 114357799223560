.cls1 {
  fill: inherit;
}

.cls2 {
  fill: none;
  stroke: inherit;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}
