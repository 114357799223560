.toolbar {
  border-bottom: 1px solid var(--surface4Color);
  display: flex;
  user-select: none;
  margin-bottom: var(--standardMargin);
  flex-direction: row;
  align-items: top;
  padding-bottom: 10px;
  flex-shrink: 0;
}

.pageToolbar {
  padding-top: var(--standardMargin);
  padding-bottom: 0px;
  min-height: 40px;
  flex-wrap: wrap;
  border-bottom: 0;
  margin-bottom: var(--smallMargin);
}

.toolbar:first-child {
}

.toolbarItem {
  flex: 0 0 auto;
  margin-right: var(--toolbarSpacing);
}

.toolbarItem.expanding {
  flex-grow: 1;
  overflow: hidden;
  max-width: 100%;
}

.pageToolbar .toolbarItem {
  margin-bottom: var(--smallMargin);
}

.toolbarItem:last-child {
  margin-right: 0;
}

.toolbarHeading {
  margin: 0;
  padding: 0;
  border-bottom: 0;
}
.toolbarPageHeading {
  margin: -5px 40px 0 0;
  padding: 8px 0px 0px 0px;
  border-bottom: 0;
}

.flexibleSpace {
  flex-grow: 1;
}

.textInput {
  border-radius: 6px;
  height: 31px;
}

.withTopMargin {
  margin-top: var(--largeMargin);
}
