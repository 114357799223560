.colorBar {
  height: 30px;
  width: 36px;
  margin-left: 4px;
  margin-right: 8px;
  border-radius: var(--tableBorderRadius);
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
}
