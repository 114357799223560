.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--standardMargin);
}

.linkArea > div {
  margin-right: var(--standardMargin);
}

.linkArea > div:last-child {
  margin-right: 0;
}

.linkArea {
  display: flex;
}

.linkArea,
.linkArea a {
  color: var(--surface3Color);
  font-size: 0.95em;
}

.active,
.active a {
  font-size: 1em;
  font-weight: bold;
  color: var(--blackColor) !important;
}

.link {
  text-decoration: none;

  color: var(--accent5Color);
  cursor: pointer;
  &:active,
  &:hover,
  &:visited {
    color: var(--accent5Color);
  }
}
