:root {
  --switchBgSize: 20px;
  --switchColor: rgba(255, 255, 255, 0.3);
  --switchWidth: 44px;
  --switchHeight: 22px;
}

.switch {
  flex-shrink: 0;
  width: var(--switchWidth);
  height: var(--switchHeight);
  border-radius: 11px;
  background: var(--whiteColor);
  padding: 3px;
  box-sizing: border-box;
  display: flex;
  border-color: var(--accent1Color);
  background: #bababa;
  cursor: pointer;
}

.switch.loading {
  cursor: default;
}

.switch.on {
  background: var(--accent1Color);
}

.loadingBackground {
  width: var(--switchWidth);
  height: calc(var(--switchHeight) + 2px);

  position: absolute;
  top: -2px;
  left: 0;
  opacity: 0;
  transition: opacity ease 300ms;
  background-image: linear-gradient(
    -45deg,
    var(--switchColor) 25%,
    transparent 25%,
    transparent 50%,
    var(--switchColor) 50%,
    var(--switchColor) 75%,
    transparent 75%,
    transparent
  );
  -webkit-background-size: var(--switchBgSize) var(--switchBgSize);
  -moz-background-size: var(--switchBgSize) var(--switchBgSize);
  -ms-background-size: var(--switchBgSize) var(--switchBgSize);
  background-size: var(--switchBgSize) var(--switchBgSize);
  -webkit-animation: progress 1.5s linear infinite;
  -moz-animation: progress 1.5s linear infinite;
  -ms-animation: progress 1.5s linear infinite;
  animation: progress 1.5s linear infinite;
}

.loadingBackground.loading {
  opacity: 1;
}

.switchKernel {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: var(--whiteColor);
  transition: margin-left ease 200ms;
}

.switchKernel.on {
  margin-left: 22px;
}

.container {
  position: relative;
}

@-webkit-keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: calc(var(--switchBgSize) * -2) 0px;
  }
}
@-moz-keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: calc(var(--switchBgSize) * -2) 0px;
  }
}
@-ms-keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: calc(var(--switchBgSize) * -2) 0px;
  }
}
@keyframes progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: calc(var(--switchBgSize) * -2) 0px;
  }
}

.disabled,
.noPointerEvents {
  pointer-events: none;
}

.disabled {
  opacity: 0.3;
}
