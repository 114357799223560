.messageBody {
  font-size: 0.85rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.searchFieldBody {
  padding-top: 0;
}

.modal {
  max-width: 600px !important;
  min-height: auto !important;
  height: auto !important;
}

.compact {
  width: 450px;
}

.fullScreen {
  max-width: 95vw !important;
  max-height: 95vh !important;
  height: 95vh !important;
}

.wide {
  max-width: 90vw !important;
  width: 950px;
}

.button {
  margin-left: 5px;
}

.spinner {
  margin-right: auto;
}

.searchField {
  flex-grow: 1;
}

.searchContainer button {
  margin-left: var(--standardMargin);
}

.searchContainer {
  display: flex;
  padding-top: var(--standardMargin);
  position: sticky;
  top: 0;
  z-index: 2;
  padding-bottom: var(--standardMargin);
  background: var(--surface6Color);
  margin-left: calc(-1 * var(--largeMargin));
  margin-right: calc(-1 * var(--largeMargin));
  padding-left: var(--largeMargin);
  padding-right: var(--largeMargin);
}

.searchContainerShadow {
  box-shadow: var(--navBarShadow);
}

.disableScroll {
  overflow-y: initial;
}

.showOverflow {
  overflow: visible;
}
