.locationTree {
  width: clamp(280px, 14vw, 350px);
  box-sizing: border-box;
  flex-direction: column;
  display: none;
  flex-shrink: 0;
  box-shadow: var(--navBarShadow);
  padding-top: var(--largeMargin);
  background: var(--surface7Color);
  margin-top: 1px;
  z-index: var(--navBarZIndex);
  word-break: break-word;
}

.spinnerContainer {
  width: 100%;
  padding: $insetMargin;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: $largeMargin;
  height: 80px;
}

.locationTree.open {
  display: flex;
}

.locationTreeView {
  overflow-y: auto;
  flex-grow: 1;
  margin-bottom: 0 !important;
  font-size: var(--labelFontSize);
  background: transparent !important;
  border-top: 1px solid var(--borderColor);
  padding-top: var(--smallMargin);
  border-radius: 0;
  min-width: auto !important;
}

.locationTreeContainer {
  height: 100%;
}

.locationTreeContainer.open {
  margin-right: var(--standardMargin);
}

.searchOptionsContainer {
  padding: 0 var(--standardMargin) var(--smallMargin);
  font-size: 0.8em;
  align-items: center;
  display: flex;
}

.space {
  flex-grow: 1;
}

.buttonSearchContainer {
  box-sizing: border-box;
  min-height: 47px;
  flex: 1 0 1px;
  width: 100%;
  padding: var(--smallMargin) var(--standardMargin) var(--smallMargin)
    var(--standardMargin);
  flex-grow: 0;
  border-bottom: 1px solid var(--borderColor);
  padding-bottom: 1px;
}

.buttonSearchContainer button {
  font-size: var(--labelFontSize);
}

.tagSearchSelect {
  width: 100%;
}

.tagSearchSelectable {
  flex-grow: 1;
  width: 100%;
}

.searchFieldContainer {
  margin-left: var(--standardMargin);
  margin-right: var(--standardMargin);
  border-top: 1px solid var(--borderColor);
  border-bottom: 1px solid var(--borderColor);

  flex-grow: 0;
}

.childTree {
  height: 100%;
  overflow-y: auto;
}

.parentTree {
  height: auto;
  margin-bottom: var(--largeMargin);
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--standardMargin);
}

.searchResults {
  overflow-y: auto;
}

.searchSectionHeader {
  padding: var(--smallMargin);
}

.traitTag > div {
  font-size: 0.9em;
}

.tags {
  display: flex;
  gap: 1px;
  flex-wrap: wrap;
}
