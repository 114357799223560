.map {
  z-index: 0;
  border-radius: 3px;
  width: 100%;
  height: 550px;
  margin-top: 15px;
}

.treeContainer {
  width: 100%;
  height: 350px;
  max-height: 350px;
  border: 1px solid var(--borderColor);
}

.topAlignedRow {
  vertical-align: top !important;
}

.valueColumn {
  width: 50% !important;
}

.modal {
  max-width: 800px !important;
  max-height: 770px !important;
}

.nameInput {
  margin-top: 20px;
  width: 100%;
}

.inputTable {
  margin-top: var(--standardMargin);
}
