.acknowledgeAllCommentInput {
  width: 100%;
  margin-top: 30px;
}

.confirmBody {
  align-items: flex-start !important;
  text-align: left;
}

.confirmAllDialog {
  max-width: 500px !important;
  width: 500px !important;
}
