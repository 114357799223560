.heading {
  color: var(--surface3Color);
  margin: 0 0 10px 0;
  padding: 0 0 6px 0;
  border-bottom: 1px solid var(--surface4Color);
  font-weight: 300;
  display: flex;
}

.marginTop {
  margin-top: var(--standardMargin);
}

.heading a {
  color: var(--surface3Color);
  text-decoration: none;
}

.h1 {
  font-size: 2.1rem;
}

.h2 {
  font-size: 1.6rem;
}

.h3 {
  font-size: 1.3rem;
}

.h4 {
  font-size: 1.12rem;
}

.h5 {
  font-size: 0.93rem;
}

.h6 {
  font-size: 0.85rem;
}

.heading button {
  margin-top: -10px;
  margin-left: 20px;
}
