@import 'react-datetime/css/react-datetime.css';

.rdtPicker {
  background: var(--surface5Color);
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--borderColor);
  color: var(--textColor);
  border-radius: var(--tableBorderRadius);
  user-select: none;
  top: 100%;
  left: 0;

  .dow,
  .rdtSwitch {
    text-transform: capitalize;
  }

  tfoot {
    border: none;
  }

  td.rdtToday:before {
    border-bottom-color: #34a1bb;
  }

  td.rdtActive,
  td.rdtActive:hover {
    background: #34a1bb;
  }

  td,
  th {
    vertical-align: middle;
    border: none;
  }
}
