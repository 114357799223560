.root {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: var(--loadingScreenZIndex);
}

.container {
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity var(--loadingScreenSpeed) ease-out;
  background: var(--whiteColor);
  pointer-events: none; /* In case someone clicks something on the loading screen */
  user-select: none;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - var(--headerHeight));
  flex-direction: column;
}

.text {
  font-weight: 100;
  margin-bottom: 20px;
}
